import { 
    RECEIVED_NAUDU_DARBUOTOJAI,
    REQUESTED_NAUDU_DARBUOTOJAI,
    UPDATE_NAUDOS_DARBUOTOJAS
} from '../constants';

export default function naudos(state = { 
    isFetching: true, 
    shouldRefresh: false, 
    list: []
}, action) 
{
  switch (action.type) {
    case REQUESTED_NAUDU_DARBUOTOJAI: 
        return Object.assign({}, state, { isFetching: true, shouldRefresh: false });
    case RECEIVED_NAUDU_DARBUOTOJAI:
        return Object.assign({}, state, { isFetching: false, list: action.payload, shouldRefresh: false});
    case UPDATE_NAUDOS_DARBUOTOJAS:
        var l = [...state.list];
        var index = l.findIndex(f => f.darbuotojasId === action.payload.darbuotojasId);
        if(index > -1) l[index] = action.payload;
        return Object.assign({}, state, { isFetching: false, list: l, shouldRefresh: false});    
    default:
        return state;
  }
}