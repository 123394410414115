import { createStore, applyMiddleware, compose } from "redux";
import { loadUser } from "redux-oidc";
import { createLogger } from "redux-logger";
import thunkMiddleware from "redux-thunk";
import reducer from "./reducers";
import userManager from "./utils/userManager";

const initialState = {}

const loggerMiddleware = createLogger();

const createStoreWithMiddleware = compose(
  applyMiddleware(loggerMiddleware, thunkMiddleware)
)(createStore);

const store = createStoreWithMiddleware(reducer, initialState);
loadUser(store, userManager);
// .then(u => {
//   store.dispatch(getDraudejai());
//   store.dispatch(getDraudimoRusys());
//   store.dispatch(getPranesimai());
//   store.dispatch(getNeapmoketiPolisai(true));
// });

export default store;
