import {
  REQUEST_PRANESIMAI,
  RECEIVED_BROKERIAI,
  RECEIVED_PRANESIMAI,
  REFRESH_PRANESIMAI,
  SET_NESKAITYTI_COUNT,
  SET_PRANESIMU_FILTRAS,
} from "../constants";

export default function pranesimai(
  state = {
    item: {},
    list: [],
    filtered: [],
    brokeriai: [],
    neskaityti: null,
    tipas: null,
  },
  action
) {
  switch (action.type) {
    case "CREATED_PRANESIMAS":
      l = [...state.list];
      index = l.findIndex((i) => i.pranesimasId === action.payload.pranesimasId);
      if (index > -1) return; // jei toks jau buvo reikia grįžti
      l = [action.payload].concat(l);
      return Object.assign({}, state, {
        filtered: l.filter((i) => i.pranesimoTipas === state.tipas),
        list: l,
      });
    case "PUT_ARCHYVAS":
      var l = [...state.list];
      var index = l.findIndex((i) => i.pranesimasId === action.payload);
      if (index > -1) {
        l[index].pranesimoTipas = 3;
      }
      return Object.assign({}, state, {
        filtered: l.filter((i) => i.pranesimoTipas === state.tipas),
        list: l,
      });
    case REFRESH_PRANESIMAI:
      return Object.assign({}, state, {
        shouldRefresh: true,
      });
    case REQUEST_PRANESIMAI:
      return Object.assign({}, state, {
        isFetching: true,
        shouldRefresh: false,
      });
    case RECEIVED_BROKERIAI:
      return Object.assign({}, state, {
        brokeriai: action.payload,
      });
    case RECEIVED_PRANESIMAI:
      return Object.assign({}, state, {
        filtered: action.payload.data.filter((i) => i.pranesimoTipas === action.payload.tipas),
        list: action.payload.data,
        tipas: action.payload.tipas,
      });
    case "RECEIVED_PRANESIMAS":
      l = [...state.list];
      index = l.findIndex((i) => i.pranesimasId === action.payload.pranesimasId);
      if (index === -1) return; 
      l[index] = { ...action.payload, isLoaded: true };
      return Object.assign({}, state, {
        filtered: l.filter((i) => i.pranesimoTipas === state.tipas),
        list: l,
      });
    case SET_NESKAITYTI_COUNT:
      return Object.assign({}, state, {
        neskaityti: action.payload,
      });
    case SET_PRANESIMU_FILTRAS:
      return Object.assign({}, state, {
        filtered: state.list.filter((i) => i.pranesimoTipas === action.payload),
        tipas: action.payload,
      });
    default:
      return state;
  }
}
