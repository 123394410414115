import {
    REQUEST_DRAUDIKAI_SHORT,
    RECEIVED_DRAUDIKAI_SHORT,
    REFRESH_DRAUDIKAI_SHORT,
    SET_DRAUDIKAI_PATH,
    SET_DRAUDIKAS_SELECTED
} from '../constants';

export default function draudikai(state = {
    isFetching: false,
    shouldRefresh: false,
    item: null,
    list: null,
    path: null,
    selection: [],
}, action) {
    switch (action.type) {
        case REFRESH_DRAUDIKAI_SHORT:
            return Object.assign({}, state, {
                shouldRefresh: true
            });
        case REQUEST_DRAUDIKAI_SHORT:
            return Object.assign({}, state, {
                isFetching: true,
                shouldRefresh: false
            });
        case RECEIVED_DRAUDIKAI_SHORT:
            return Object.assign({}, state, {
                isFetching: false,
                list: action.payload,
                shouldRefresh: false
            });
        case SET_DRAUDIKAI_PATH:
            return Object.assign({}, state, {
                path: action.payload
            });
        case SET_DRAUDIKAS_SELECTED:
            return Object.assign({}, state, {
                selection: action.payload
            });
        default:
            return state;
    }
}