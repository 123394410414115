import { 
    GET_KONTAKTAI
} from '../constants';

function kontaktai(state = { tekstas: '' }, action) {
  switch (action.type) {
    case GET_KONTAKTAI:
        return Object.assign({}, state, { tekstas: action.payload });
    default:
        return state;
  }
}

export default kontaktai;