export default function isInRole(role, user) {
    // Ar naudotojas turi role Polisai?
    // Jei naudotojo nėra, false;
    // Jei naudotojas yra bet naudotojas neturi rolės Polisai arba Viskas, false;
    // Jei naudotojas yra ir turi rolę Viskas, true;
    // Jei naudotojas yra ir turi rolę Polisai, true;
    //
    // Ar naudotojas turi rolę null?
    // Jei naudotojo nėra, false;
    // Jei naudotojas yra, true;
    if (role && !user) return false;
    if (!role && !user) return false;
    if (!role) return true;
    var roles = user.profile.role;
    if (!roles) return false;
    if (roles.indexOf("Viskas") > -1) return true;
    if (roles.indexOf(role) > -1) return true;
    return false;
}
