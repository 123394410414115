import {
  PUT_POLISAI_FILTRAS,
  REQUEST_POLISAS,
  REQUEST_POLISAI,
  RECEIVED_POLISAS,
  RECEIVED_POLISAI,
  REFRESH_POLISAI,
  SET_POLISAI_FILTRAS,
  SET_POLISAS_ID,
  VELUOJANCIOS_IMOKOS_COUNT
} from "../constants";

export default function polisai(
  state = {
    // filtras: null,
    // isFetching: false,
    // shouldRefresh: false,
    // item: null,
    // items: null,
    // list: null,
    // veluojantys: null
  },
  action
) {
  switch (action.type) {
    case PUT_POLISAI_FILTRAS:
      return Object.assign({}, state, { filtras: action.payload });
    case REFRESH_POLISAI:
      return Object.assign({}, state, { shouldRefresh: true });
    case REQUEST_POLISAS:
    case REQUEST_POLISAI:
      return Object.assign({}, state, {
        isFetching: true,
        shouldRefresh: false
      });
    case RECEIVED_POLISAS:
      return Object.assign({}, state, {
        isFetching: false,
        list: action.list,
        items: action.items,
        shouldRefresh: false
      });
    case RECEIVED_POLISAI:
      return Object.assign({}, state, {
        isFetching: false,
        list: action.list,
        items: action.items,
        shouldRefresh: false
      });
    case SET_POLISAI_FILTRAS:
      return Object.assign({}, state, { list: action.payload });
    case SET_POLISAS_ID:
        return Object.assign({}, state, { id: action.payload });
    case VELUOJANCIOS_IMOKOS_COUNT:
      return Object.assign({}, state, { veluojantys: action.payload });
    default:
      return state;
  }
}
