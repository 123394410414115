import {
  REQUEST_RIZIKOS,
  RECEIVED_RIZIKOS,
  REFRESH_RIZIKOS
} from "../constants";

export default function rizikos(
  state = {
    isFetching: false,
    shouldRefresh: false,
    draudimoRusys: {},
    draudejuPolisai: {},
    draudejuPolisuSumos: {}
  },
  action
) {
  switch (action.type) {
    case REFRESH_RIZIKOS:
      return Object.assign({}, state, { shouldRefresh: true });
    case REQUEST_RIZIKOS:
      return Object.assign({}, state, {
        isFetching: true,
        shouldRefresh: false
      });
    case RECEIVED_RIZIKOS:
      return Object.assign({}, state, {
        isFetching: false,
        draudimoRusys: action.draudimoRusys,
        draudejuPolisai: action.draudejuPolisai,
        draudejuPolisuSumos: action.draudejuPolisuSumos,
        shouldRefresh: false
      });
    default:
      return state;
  }
}
