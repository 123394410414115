import React, { useContext } from "react";
import { LanguageContext } from "../language";
//import { Link } from "react-router-dom";
import "./footer.css";

const Footer = () => {
  const { strings } = useContext(LanguageContext);

  return (
    <footer className="no-print">
      <section className="footer-main">
        <div className="shell">
          <div className="range">
            <div className="cell-sm-6 cell-md-4 text-center text-white">
              <img src="/images/IVP logo white.png" alt="" className="footer-logo" />
              <div className="section-top-15 footer-heading">{strings["Inovatyvus Jūsų verslo partneris"]}</div>
              <div className="section-top-15">UADBB IVP Partners</div>
              <div>{strings["Įmonės kodas"]} 302489781</div>
              <div>
                <a href="https://www.ivp.lt" target="_blank" rel="noopener noreferrer" className="link-footer-simple">
                  www.ivp.lt
                </a>
              </div>
            </div>
            <div className="cell-sm-6 cell-md-4 text-center text-white">
              <div className="footer-heading">{strings["Susisiekime"]}</div>
              <div className="section-top-15">{strings["Pagrindinė būstinė"]}</div>
              <div>{strings["Ulonų g. 2, Vilnius, LT-08245 Lietuva"]}</div>
              <div>
                <span className="fa fa-phone" />
                &nbsp;&nbsp;+370 5 219 7601
              </div>
              <div>
                <span className="fa fa-envelope text-white" />
                &nbsp;&nbsp;info@ivp.lt
              </div>
              <div></div>
              <div className="section-top-15">{strings["Filialas Klaipėdoje"]}</div>
              <div>{strings["Šilutės pl. 2-539, LT-91111 Klaipėda"]}</div>
              <div>
                <span className="fa fa-phone" />
                &nbsp;&nbsp;+370 46 313049
              </div>
            </div>
            <div className="cell-sm-6 cell-md-4 text-center text-white">
              <div className="footer-heading">{strings["Darbo valandos"]}</div>
              <div className="section-top-15">{strings["Pirmadienis - penktadienis: 8:00 - 17:00"]}</div>
              <div>{strings["Pietūs: 11:30 - 12:30"]}</div>
              <div>{strings["Savitarna: 24/7"]}</div>
            </div>
            <div className="cell-sm-12 cell-md-12 offset-top-15 offset-sm-top-0 text-center">
              <div className="group-sm group-middle">
                <p style={{ marginTop: "20px" }}>
                  <a
                    href={strings["/informacija_klientui.pdf"]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link-footer indent"
                  >
                    {strings["Informacija draudėjui"]}
                  </a>
                  <a
                    href={strings["/duomenu_subjekto_atmintine.pdf"]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link-footer indent"
                  >
                    {strings["Duomenų subjekto atmintinė"]}
                  </a>
                  <a
                    href={strings["/tvarumo_politika.pdf"]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link-footer indent"
                  >
                    {strings["Tvarumo politika"]}
                  </a>
                  <a
                    href={strings["/Informacijos_saugumo_politika.pdf"]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link-footer indent"
                  >
                    {strings["Informacijos saugumo politika"]}
                  </a>
                  <a
                    href={strings["/slapuku_politika.pdf"]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link-footer indent"
                  >
                    {strings["Slapukų politika"]}
                  </a>
                  <a
                    href={strings["/savitarnos_taisykles.pdf"]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link-footer indent"
                  >
                    {strings["Savitarnos svetainės taisyklės"]}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="footer-bottom">
        <div className="container">
          <div className="light-gray-text system-ui">
            © IVP Partners {new Date().getFullYear()}
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
