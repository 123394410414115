import React from "react";
import { Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import asyncComponent from "./components/asyncComponent";
import Footer from "./components/Footer";
import Header from "./components/header";
import { ToastContainer } from "react-toastify";
import $ from "jquery";
import "./bootstrap/style.css";
import "react-toastify/dist/ReactToastify.css";
import "./react-bootstrap-table2.css";
import "./App.css";
import "./assets/script";
import apiRequest from "./utils/request";
import {
  RECEIVED_DRAUDEJAI_SHORT,
  RECEIVED_DRAUDIKAI_SHORT,
  RECEIVED_DRAUDIMO_RUSYS,
  SET_NESKAITYTI_COUNT,
  VELUOJANCIOS_IMOKOS_COUNT,
} from "./constants";
import Pranesimas from "./components/pranesimas";
import isInRole from "./utils/isInRole";

if (!Date.prototype.toISODate) {
  Date.prototype.toISODate = function () {
    return this.getFullYear() + "-" + ("0" + (this.getMonth() + 1)).slice(-2) + "-" + ("0" + this.getDate()).slice(-2);
  };
}

const AsyncApklausos = asyncComponent(() => import("./containers/apklausos"));
const AsyncApklausosPateikti = asyncComponent(() => import("./containers/apklausos/pateikti"));

const AsyncAtaskaitos = asyncComponent(() => import("./containers/ataskaitos"));
const AsyncAtaskaitosList = asyncComponent(() => import("./containers/ataskaitos/ataskaitosList"));
const AsyncGyvybesPokyciuAtaskaita = asyncComponent(() => import("./containers/ataskaitos/gyvybesPokyciuAtaskaita"));

const AsyncAtsiliepimai = asyncComponent(() => import("./containers/atsiliepimai"));
const AsyncCallback = asyncComponent(() => import("./components/callback"));
const AsyncDokumentai = asyncComponent(() => import("./containers/dokumentai"));
const AsyncHome = asyncComponent(() => import("./containers/home"));
const AsyncImokos = asyncComponent(() => import("./containers/imokos"));
const AsyncKontaktai = asyncComponent(() => import("./containers/kontaktai"));
const AsyncLogin = asyncComponent(() => import("./containers/paskyros/login"));
const AsyncChangePassword = asyncComponent(() => import("./containers/paskyros/changePassword"));
const AsyncForgotPassword = asyncComponent(() => import("./containers/paskyros/forgotPassword"));
const AsyncResetPassword = asyncComponent(() => import("./containers/paskyros/resetPassword"));
const AsyncPasiulymai = asyncComponent(() => import("./containers/pasiulymai"));
const AsyncPolisai = asyncComponent(() => import("./containers/polisai"));
const AsyncPranesimai = asyncComponent(() => import("./containers/pranesimai"));
const AsyncPrivatumas = asyncComponent(() => import("./components/privatumas"));
const AsyncSilent = asyncComponent(() => import("./components/silent"));
// const AsyncStatistika = asyncComponent(() => import("./containers/statistika"));
// const AsyncDiagramos = asyncComponent(() => import("./containers/statistika/diagramos"));
// const AsyncRizikos = asyncComponent(() => import("./containers/statistika/rizikos"));
//const AsyncDarbuotojai = asyncComponent(() => import("./containers/darbuotojai"));
const AsyncSveikata = asyncComponent(() => import("./containers/sveikata"));
const AsyncGyvybe = asyncComponent(() => import("./containers/gyvybes"));
const AsyncTaisykles = asyncComponent(() => import("./components/taisykles"));
const AsyncTransportas = asyncComponent(() => import("./containers/transportas"));
const AsyncPrasymai = asyncComponent(() => import("./containers/prasymai"));
const AsyncZalos = asyncComponent(() => import("./containers/zalos"));

const AsyncNaudos = asyncComponent(() => import("./containers/naudos"));
const AsyncNauda = asyncComponent(() => import("./containers/naudos/editNauda"));
const AsyncSablonas = asyncComponent(() => import("./containers/naudos/editSablonas"));
const AsyncNauduValdymas = asyncComponent(() => import("./containers/naudos/nauduValdymas"));
const AsyncMatrica = asyncComponent(() => import("./containers/naudos/matrica"));
const AsyncGrupiuValdymas = asyncComponent(() => import("./containers/naudos/grupiuValdymas"));
const AsyncGrupe = asyncComponent(() => import("./containers/naudos/editGrupe"));
const AsyncApklausuValdymas = asyncComponent(() => import("./containers/naudos/apklausuValdymas"));
const AsyncApklausa = asyncComponent(() => import("./containers/naudos/editApklausa"));
const AsyncRezultatai = asyncComponent(() => import("./containers/naudos/rezultatai"));
const AsyncNauduAtaskaitos = asyncComponent(() => import("./containers/naudos/ataskaitos"));

const App = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.oidc);
  const draudejai = useSelector((state) => state.draudejai.list);
  const draudikai = useSelector((state) => state.draudikai.list);
  const draudimoRusys = useSelector((state) => state.draudimoRusys.list);
  const neskaityti = useSelector((state) => state.pranesimai.neskaityti);
  const veluojantys = useSelector((state) => state.polisai.veluojantys);

  React.useEffect(() => {
    var pageLoader = $(".page-loader");
    setTimeout(function () {
      pageLoader.addClass("loaded");
      $(window).trigger("resize");
    }, 200);
  }, []);

  const getDraudejai = async () => {
    if (draudejai) return;
    var data = await apiRequest("draudejai", "GET");
    dispatch({ type: RECEIVED_DRAUDEJAI_SHORT, payload: data });
  };

  const getDraudikai = async () => {
    if (draudikai) return;
    var data = await apiRequest("draudikai", "GET");
    dispatch({ type: RECEIVED_DRAUDIKAI_SHORT, payload: data });
  };

  const getDraudimoRusys = async () => {
    if (draudimoRusys) return;
    var data = await apiRequest("draudimoRusys/getDraudimoRusys", "GET");
    dispatch({ type: RECEIVED_DRAUDIMO_RUSYS, payload: data });
  };

  const getVeluojanciosImokosCount = async () => {
    if (veluojantys || veluojantys == 0) return;
    var data = await apiRequest("polisai/getVeluojanciosImokosCount", "GET");
    dispatch({ type: VELUOJANCIOS_IMOKOS_COUNT, payload: data });
  };

  const getNeskaitytiPranesimaiCount = async () => {
    if (neskaityti) return;
    var data = await apiRequest("pranesimai/getNeskaitytiPranesimaiCount", "GET");
    dispatch({ type: SET_NESKAITYTI_COUNT, payload: data });
  };

  React.useEffect(() => {
    if (user && !user.expired) {
      getDraudejai();
      getDraudikai();
      getDraudimoRusys();
      getNeskaitytiPranesimaiCount();
      if (isInRole("Imokos", user)) {
        getVeluojanciosImokosCount();
      }
    }
  }, [user]);

  return (
    <div className="page">
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
        theme="colored"
      />
      <Header />
      <Pranesimas />
      <Routes>
        <Route exact path="/" element={<AsyncHome />} />
        <Route path="/kontaktai" element={<AsyncKontaktai />} />
        <Route path="/callback" element={<AsyncCallback />} />
        <Route path="/silent" element={<AsyncSilent />} />
        <Route path="/privatumas" element={<AsyncPrivatumas />} />
        <Route path="/taisykles" element={<AsyncTaisykles />} />
        <Route path="/atsiliepimai" element={<AsyncAtsiliepimai />} />
        <Route path="/login" element={<AsyncLogin />} />
        <Route path="/forgotPassword" element={<AsyncForgotPassword />} />
        <Route path="/resetPassword" element={<AsyncResetPassword />} />

        <Route path="ataskaitos" element={user && !user.expired ? <AsyncAtaskaitos /> : null} >
          <Route path="gyvybes_pokyciu_ataskaita" element={user && !user.expired ? <AsyncGyvybesPokyciuAtaskaita /> : null} />
          <Route index element={user && !user.expired ? <AsyncAtaskaitosList /> : null} />
        </Route>

        <Route path="/password" element={user && !user.expired ? <AsyncChangePassword /> : null} />
        <Route path="/darbuotojai" element={user && !user.expired && isInRole("Darbuotojai", user) && !isInRole("Gyvybe", user) && !isInRole("Apklausos", user) && isInRole("Naudos", user) ? <AsyncSveikata /> : null} />
        <Route path="apklausos/:apklausaId/:darbuotojasId" element={user && !user.expired && isInRole("Apklausos", user)? <AsyncApklausosPateikti /> : null} />
        <Route path="apklausos/:apklausaId" element={user && !user.expired && isInRole("Apklausos", user) ? <AsyncApklausosPateikti /> : null} />
        <Route path="apklausos" element={user && !user.expired && isInRole("Apklausos", user) ? <AsyncApklausos /> : null} />
        <Route path="gyvybe/:id" element={user && !user.expired && isInRole("Gyvybe", user) ? <AsyncGyvybe /> : null} />
        <Route path="gyvybe" element={user && !user.expired && isInRole("Gyvybe", user) ? <AsyncGyvybe /> : null} />
        <Route path="sveikata/:id" element={user && !user.expired && isInRole("Darbuotojai", user) ? <AsyncSveikata /> : null} />
        <Route path="sveikata" element={user && !user.expired && isInRole("Darbuotojai", user) ? <AsyncSveikata /> : null} />
        <Route path="naudos" element={user && !user.expired && isInRole("Naudos", user) ? <AsyncNaudos /> : null}>
          <Route path="naudos" element={user && !user.expired && isInRole("Naudos", user) ? <AsyncNauduValdymas /> : null} />
          <Route path="nauda/:id" element={user && !user.expired && isInRole("Naudos", user) ? <AsyncNauda /> : null} />
          <Route path="nauda" element={user && !user.expired && isInRole("Naudos", user) ? <AsyncNauda /> : null} />
          <Route path="sablonas/:id" element={user && !user.expired && isInRole("Naudos", user) ? <AsyncSablonas /> : null} />
          <Route path="sablonas/" element={user && !user.expired && isInRole("Naudos", user) ? <AsyncSablonas /> : null} />
          <Route path="matrica" element={user && !user.expired && isInRole("Naudos", user) ? <AsyncMatrica /> : null} />
          <Route path="grupes" element={user && !user.expired && isInRole("Naudos", user) ? <AsyncGrupiuValdymas /> : null} />
          <Route path="grupe/:id" element={user && !user.expired && isInRole("Naudos", user) ? <AsyncGrupe /> : null} />
          <Route path="grupe/" element={user && !user.expired && isInRole("Naudos", user) ? <AsyncGrupe /> : null} />
          <Route path="apklausos" element={user && !user.expired && isInRole("Naudos", user) ? <AsyncApklausuValdymas /> : null} />
          <Route path="apklausa/:id" element={user && !user.expired && isInRole("Naudos", user) ? <AsyncApklausa /> : null} />
          <Route path="apklausa" element={user && !user.expired && isInRole("Naudos", user) ? <AsyncApklausa /> : null} />
          <Route path="rezultatai/:id" element={user && !user.expired && isInRole("Naudos", user) ? <AsyncRezultatai /> : null} />
          <Route path="ataskaitos" element={user && !user.expired && isInRole("Naudos", user) ? <AsyncNauduAtaskaitos /> : null} />
          <Route index element={user && !user.expired && isInRole("Naudos", user) ? <AsyncMatrica /> : null} />
        </Route>

        <Route path="dokumentai" element={user && !user.expired && isInRole("Dokumentai", user) ? <AsyncDokumentai /> : null} />
        <Route path="imokos" element={user && !user.expired && isInRole("Imokos", user) ? <AsyncImokos /> : null} />

        <Route path="pasiulymai" element={user && !user.expired ? <AsyncPasiulymai /> : null} />
        <Route path="polisai/:id" element={user && !user.expired && isInRole("Polisai", user) ? <AsyncPolisai /> : null} />
        <Route path="polisai/" element={user && !user.expired && isInRole("Polisai", user) ? <AsyncPolisai /> : null} />
        <Route path="pranesimai/visos" element={user && !user.expired ? <AsyncPranesimai /> : null} />
        <Route path="pranesimai/gautos" element={user && !user.expired ? <AsyncPranesimai /> : null} />
        <Route path="pranesimai/issiustos" element={user && !user.expired ? <AsyncPranesimai /> : null} />
        <Route path="pranesimai/archyvas" element={user && !user.expired ? <AsyncPranesimai /> : null} />
        <Route path="pranesimai/nauja" element={user && !user.expired ? <AsyncPranesimai /> : null} />
        <Route path="pranesimai/dokumentai" element={user && !user.expired ? <AsyncDokumentai /> : null} />
        <Route path="pranesimai" element={user && !user.expired ? <AsyncPranesimai /> : null} />
        <Route path="prasymai/:id" element={user && !user.expired && (isInRole("Transportas", user) || isInRole("Darbuotojai", user)) ? <AsyncPrasymai /> : null} />
        <Route path="prasymai" element={user && !user.expired && (isInRole("Transportas", user) || isInRole("Darbuotojai", user)) ? <AsyncPrasymai /> : null} />
        <Route path="transportas/:id" element={user && !user.expired && isInRole("Transportas", user) ? <AsyncTransportas /> : null} />
        <Route path="transportas" element={user && !user.expired && isInRole("Transportas", user) ? <AsyncTransportas /> : null} />
        <Route path="zalos/?atidarytos" element={user && !user.expired && isInRole("Žalos", user) ? <AsyncZalos /> : null} />
        <Route path="zalos" element={user && !user.expired && isInRole("Žalos", user) ? <AsyncZalos /> : null} />
      </Routes>
      <Footer />
    </div>
  );
};
export default App;
