import { 
    SET_SIDEBAR,
    SET_SIDEBAR_COUNT,
    SET_SIDEBAR_MENU,
    SET_SIDEBAR_SUBMENU
} from '../constants';

export default function sidebar(state = { count: null, menu: [], submenu: [], pagalba: {}}, action) {
  switch (action.type) {
    case SET_SIDEBAR_COUNT:
        return Object.assign({}, state, { count: action.payload });
    case SET_SIDEBAR_MENU:
        return Object.assign({}, state, { menu: action.payload });
    case SET_SIDEBAR_SUBMENU: 
        return Object.assign({}, state, { submenu: action.payload });
    case SET_SIDEBAR: 
        return Object.assign({}, state, action.payload);

    default:
        return state;
  }
}

