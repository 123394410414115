export const USER_EXPIRED = 'USER_EXPIRED';
export const REDIRECT_SUCCESS = 'REDIRECT_SUCCESS';
export const USER_LOADED = 'USER_LOADED';
export const SILENT_RENEW_ERROR = 'SILENT_RENEW_ERROR';
export const SESSION_TERMINATED = 'SESSION_TERMINATED';
export const USER_EXPIRING = 'USER_EXPIRING';
export const USER_FOUND = 'USER_FOUND';
export const LOADING_USER = 'LOADING_USER';
export const USER_SIGNED_OUT = 'USER_SIGNED_OUT';
export const LOAD_USER_ERROR = 'LOAD_USER_ERROR';

export const IMPORTED_DARBUOTOJAI = 'IMPORTED_DARBUOTOJAI';
export const IMPORTING_DARBUOTOJAI = 'IMPORTING_DARBUOTOJAI';
export const PUT_DARBUOTOJAI_FILTRAS = 'PUT_DARBUOTOJAI_FILTRAS';
export const RECEIVED_DARBUOTOJAI = 'RECEIVED_DARBUOTOJAI';
export const RECEIVED_DARBUOTOJAI_SHORT = 'RECEIVED_DARBUOTOJAI_SHORT';
export const RECEIVED_DARBUOTOJAS = 'RECEIVED_DARBUOTOJAS';
export const REQUEST_DARBUOTOJAI = 'REQUEST_DARBUOTOJAI';
export const REFRESH_DARBUOTOJAI = 'REFRESH_DARBUOTOJAI';
export const SET_DARBUOTOJAI_FILTRAS = 'SET_DARBUOTOJAI_FILTRAS';
export const UPDATE_DARBUOTOJAI = 'UPDATE_DARBUOTOJAI';
export const UPDATE_SORT = 'UPDATE_SORT';

export const RECEIVED_DOKUMENTAI = 'RECEIVED_DOKUMENTAI';
export const REFRESH_DOKUMENTAI = 'REFRESH_DOKUMENTAI';
export const REQUEST_DOKUMENTAI = 'REQUEST_DOKUMENTAI';
export const PUT_DOKUMENTAI_FILTRAS = 'PUT_DOKUMENTAI_FILTRAS';
export const SET_DOKUMENTAI_FILTRAS = 'SET_DOKUMENTAI_FILTRAS';

export const RECEIVED_DRAUDEJAI_SHORT = 'RECEIVED_DRAUDEJAI_SHORT';
export const REFRESH_DRAUDEJAI_SHORT = 'REFRESH_DRAUDEJAI_SHORT';
export const REQUEST_DRAUDEJAI_SHORT = 'REQUEST_DRAUDEJAI_SHORT';
export const SET_DRAUDEJAS_FILTRAS = 'SET_DRAUDEJAS_FILTRAS';
export const SET_DRAUDEJAI_PATH = 'SET_DRAUDEJAI_PATH';
export const SET_DRAUDEJAS_SELECTED = 'SET_DRAUDEJAS_SELECTED';
export const SET_DRAUDEJAS_SINGLE_SELECTED = "SET_DRAUDEJAS_SINGLE_SELECTED";

export const REQUEST_DRAUDIKAI_SHORT = 'REQUEST_DRAUDIKAI_SHORT';
export const RECEIVED_DRAUDIKAI_SHORT = 'RECEIVED_DRAUDIKAI_SHORT';
export const REFRESH_DRAUDIKAI_SHORT = 'REFRESH_DRAUDIKAI_SHORT';
export const SET_DRAUDIKAI_PATH = 'SET_DRAUDIKAI_PATH';
export const SET_DRAUDIKAS_SELECTED = 'SET_DRAUDIKAS_SELECTED';

export const REQUEST_DRAUDIMO_RUSYS = 'REQUEST_DRAUDIMO_RUSYS';
export const RECEIVED_DRAUDIMO_RUSYS = 'RECEIVED_DRAUDIMO_RUSYS';
export const REFRESH_DRAUDIMO_RUSYS = 'REFRESH_DRAUDIMO_RUSYS';
export const SET_DRAUDIMO_RUSIS_ID = 'SET_DRAUDIMO_RUSIS_ID';
export const SET_DRAUDIMO_RUSYS_PATH = 'SET_DRAUDIMO_RUSYS_PATH';

export const RECEIVED_FAILAI = 'RECEIVED_FAILAI';
export const REQUESTED_FAILAI = 'REQUESTED_FAILAI';

export const PUT_IMOKOS_FILTRAS = 'PUT_IMOKOS_FILTRAS';
export const REQUEST_IMOKOS = 'REQUEST_IMOKOS';
export const RECEIVED_IMOKOS = 'RECEIVED_IMOKOS';
export const REFRESH_IMOKOS = 'REFRESH_IMOKOS';
export const SET_IMOKOS_DRAUDEJAS_FILTRAS = 'SET_IMOKOS_DRAUDEJAS_FILTRAS';
export const SET_IMOKOS_DRAUDIMO_RUSYS_FILTRAS = 'SET_IMOKOS_DRAUDIMO_RUSYS_FILTRAS';
export const SET_IMOKOS_FILTRAS = 'SET_IMOKOS_FILTRAS';

export const SET_LANGUAGE_CODE = 'SET_LANGUAGE_CODE';

export const RECEIVED_NAUDOS = 'RECEIVED_NAUDOS';
export const REQUESTED_NAUDOS = 'REQUESTED_NAUDOS';
export const UPDATE_NAUDA = 'UPDATE_NAUDA';
export const RECEIVED_NAUDU_DARBUOTOJAI = "RECEIVED_NAUDU_DARBUOTOJAI";
export const REQUESTED_NAUDU_DARBUOTOJAI = "REQUESTED_NAUDU_DARBUOTOJAI";
export const UPDATE_NAUDOS_DARBUOTOJAS = "UPDATE_NAUDOS_DARBUOTOJAS";
export const RECEIVED_NAUDU_DARBUOTOJU_GRUPES = "RECEIVED_NAUDU_DARBUOTOJU_GRUPES";
export const REQUESTED_NAUDU_DARBUOTOJU_GRUPES = "REQUESTED_NAUDU_DARBUOTOJU_GRUPES";
export const UPDATE_NAUDU_DARBUOTOJU_GRUPE = "UPDATE_NAUDU_DARBUOTOJU_GRUPE";


export const POST_POLISAS = 'POST_POLISAS';
export const PUT_POLISAI_FILTRAS = 'PUT_POLISAI_FILTRAS';
export const REQUEST_POLISAS = 'REQUEST_POLISAS';
export const REQUEST_POLISAI = 'REQUEST_POLISAI';
export const RECEIVED_POLISAS = 'RECEIVED_POLISAS';
export const RECEIVED_POLISAI = 'RECEIVED_POLISAI';
export const RECEIVED_BESIBAIGIANTYS_POLISAI = 'RECEIVED_BESIBAIGIANTYS_POLISAI';
export const RECEIVED_NEAPMOKETI_POLISAI = 'RECEIVED_NEAPMOKETI_POLISAI';
export const RECEIVED_SENI_POLISAI = 'RECEIVED_SENI_POLISAI';
export const REFRESH_POLISAI = 'REFRESH_POLISAI';
export const SET_POLISAI_DRAUDEJAS_FILTRAS = 'SET_POLISAI_DRAUDEJAS_FILTRAS';
export const SET_POLISAI_DRAUDIMO_RUSYS_FILTRAS = 'SET_POLISAI_DRAUDIMO_RUSYS_FILTRAS';
export const SET_POLISAI_FILTRAS = 'SET_POLISAI_FILTRAS';
export const SET_POLISAI_URL = 'SET_POLISAI_URL';
export const SET_POLISAS_ID = 'SET_POLISAS_ID';
export const SET_POLISO_FAILAI = 'SET_POLISO_FAILAI';
export const VELUOJANCIOS_IMOKOS_COUNT = 'VELUOJANCIOS_IMOKOS_COUNT';

export const REQUEST_PRANESIMAI = 'REQUEST_PRANESIMAI';
export const RECEIVED_BROKERIAI = 'RECEIVED_BROKERIAI';
export const RECEIVED_PRANESIMAI = 'RECEIVED_PRANESIMAI';
export const REFRESH_PRANESIMAI = 'REFRESH_PRANESIMAI';
export const SET_NESKAITYTI_COUNT = 'SET_NESKAITYTI_COUNT';
export const SET_PRANESIMU_FILTRAS = 'SET_PRANESIMU_FILTRAS';

export const REQUEST_RIZIKOS = 'REQUEST_RIZIKOS';
export const RECEIVED_RIZIKOS = 'RECEIVED_RIZIKOS';
export const REFRESH_RIZIKOS = 'REFRESH_RIZIKOS';

export const SET_SIDEBAR = 'SET_SIDEBAR';
export const SET_SIDEBAR_COUNT = 'SET_SIDEBAR_COUNT';
export const SET_SIDEBAR_MENU = 'SET_SIDEBAR_MENU';
export const SET_SIDEBAR_SUBMENU = 'SET_SIDEBAR_SUBMENU';

export const RECEIVED_IMOKOS_ISMOKOS = 'RECEIVED_IMOKOS_ISMOKOS';
export const RECEIVED_IMOKOS_ISMOKOS_SU_DR = 'RECEIVED_IMOKOS_ISMOKOS_SU_DR';
export const RECEIVED_ISLAIDOS_PAGAL_RUSIS = 'RECEIVED_ISLAIDOS_PAGAL_RUSIS';
export const REQUEST_IMOKOS_ISMOKOS = 'REQUEST_IMOKOS_ISMOKOS';
export const REQUEST_ISLAIDOS_PAGAL_RUSIS = 'REQUEST_ISLAIDOS_PAGAL_RUSIS';
export const REFRESH_DIAGRAMOS = 'REFRESH_DIAGRAMOS';
export const SET_STATISTIKA_DRAUDEJAS_FILTRAS = 'SET_STATISTIKA_DRAUDEJAS_FILTRAS';
export const SET_STATISTIKA_DRAUDIMO_RUSIS_FILTRAS = 'SET_STATISTIKA_DRAUDIMO_RUSIS_FILTRAS';
export const SET_STATISTIKA_METAI = 'SET_STATISTIKA_METAI';

export const PUT_TRANSPORTAS_FILTRAS = 'PUT_TRANSPORTAS_FILTRAS';
export const RECEIVED_TRANSPORTAS = 'RECEIVED_TRANSPORTAS';
export const REFRESH_TRANSPORTAS = 'REFRESH_TRANSPORTAS';
export const REQUEST_TRANSPORTAS = 'REQUEST_TRANSPORTAS';
export const SET_TRANSPORTAS_FILTRAS = 'SET_TRANSPORTAS_FILTRAS';
export const SET_TRANSPORTAS_DRAUDEJAS_FILTRAS = 'SET_TRANSPORTAS_DRAUDEJAS_FILTRAS';

export const POST_TURTAS = 'POST_TURTAS';
export const REQUEST_TURTAS_ITEM = 'REQUEST_TURTAS_ITEM';
export const REQUEST_TURTAS_LIST = 'REQUEST_TURTAS_LIST';
export const RECEIVED_TURTAS_ITEM = 'RECEIVED_TURTAS_ITEM';
export const RECEIVED_TURTAS_LIST = 'RECEIVED_TURTAS_LIST';
export const REFRESH_TURTAS_LIST = 'REFRESH_TURTAS_LIST';


export const POST_ZALA = 'POST_ZALA';
export const PUT_ZALOS_FILTRAS = 'PUT_ZALOS_FILTRAS';
export const RECEIVED_ZALA = 'RECEIVED_ZALA';
export const RECEIVED_ZALOS = 'RECEIVED_ZALOS';
export const REQUEST_ZALA = 'REQUEST_ZALA';
export const REQUEST_ZALOS = 'REQUEST_ZALOS';
export const REFRESH_ZALA = 'REFRESH_ZALA';
export const SAVING_ZALA = 'SAVING_ZALA';
export const SAVED_ZALA = 'SAVED_ZALA';
export const SET_ZALOS_DRAUDEJAS_FILTRAS = 'SET_ZALOS_DRAUDEJAS_FILTRAS';
export const SET_ZALOS_DRAUDIMO_RUSIS_ID = 'SET_ZALOS_DRAUDIMO_RUSIS_ID';
export const SET_ZALOS_FAILAI = 'SET_ZALOS_FAILAI';
export const SET_ZALOS_FILTRAS = 'SET_ZALOS_FILTRAS';

export const SET_DRAUDIMO_RUSYS = 'SET_DRAUDIMO_RUSYS';
export const SET_DRAUDIMO_RUSIS_FILTRAS = 'SET_DRAUDIMO_RUSYS_FILTRAS';

export const GET_KONTAKTAI = 'GET_KONTAKTAI';
export const SAFE_FILE_TYPES = 'text/csv, application/vnd.ms-excel, text/x-csv, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/html, .msg, application/vnd.ms-outlook, application/vnd.oasis.opendocument.text, application/x-vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.presentation, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/rtf, text/plain, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/zip, audio/*, image/*, video/*';