import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import userManager from "../utils/userManager";
import isInRole from "../utils/isInRole";
import { LanguageContext } from "../language";

const SignIn = () => {
  const { strings } = useContext(LanguageContext);
  
  const onLoginButtonClick = (event) => {
    event.preventDefault();
    userManager.signinRedirect();
  };

  return (
    <React.Fragment>
      <li>
        <div className="unit unit-horizontal unit-spacing-xs unit-middle">
          <div className="unit-body">
            <button
              onClick={onLoginButtonClick}
              className="btn-link link-secondary"
              style={{ cursor: "pointer", fontSize: "18px" }}
            >
              <span className="icon icon-xxs icon-primary fa-sign-in" /> {strings["Prisijungti"]}
            </button>
          </div>
        </div>
      </li>
      <li>
        <div className="unit unit-horizontal unit-spacing-xs unit-middle"></div>
      </li>
    </React.Fragment>
  );
};

// const Profile = ({ user }) => {
//   const { strings } = useContext(LanguageContext);
//   const onLogoutButtonClick = (event) => {
//     event.preventDefault();
//     userManager.signoutRedirect();
//   };

//   const onProfileButtonClick = () => {
//     var redirect_uri = `${window.location.protocol}//${window.location.hostname}${
//       window.location.port ? `:${window.location.port}` : ""
//     }`;
//     window.location = `${process.env.REACT_APP_AUTHENTICATIONSRV_URL}Manage/Index?returnUrl=${redirect_uri}`;
//   };

//   return (
//     <>
//       <li>
//         <div
//           className="unit unit-horizontal unit-spacing-xs unit-middle"
//           title="Paspauskite, kad peržiūrėti savo profilį."
//         >
//           <div className="unit-left">
//             <span className="icon icon-xxs icon-primary fa-user" />
//           </div>
//           <div className="unit-body">
//             <button onClick={onProfileButtonClick} className="btn-link link-secondary" style={{ cursor: "pointer" }}>
//               {user.profile.name}
//             </button>
//           </div>
//         </div>
//       </li>
//       <li>
//         <div className="unit unit-horizontal unit-spacing-xs unit-middle">
//           <div className="unit-left">
//             <span className="icon icon-xxs icon-primary fa-sign-out" />
//           </div>
//           <div className="unit-body">
//             <button onClick={onLogoutButtonClick} className="btn-link link-secondary" style={{ cursor: "pointer" }}>
//               {strings["Atsijungti"]}
//             </button>
//           </div>
//         </div>
//       </li>
//     </>
//   );
// };

const Header = () => {
  const { language, updateLanguage } = useContext(LanguageContext);
  const { strings } = useContext(LanguageContext);
  const user = useSelector((state) => state.oidc.user);
  const { neskaityti } = useSelector((state) => state.pranesimai);
  const { pathname } = useLocation();
  const [activeLink, setActiveLink] = React.useState();
  const zalosUrl = user
    ? `${process.env.REACT_APP_ZALOS_URL}?sender=${user?.profile?.sub}`
    : process.env.REACT_APP_ZALOS_URL;

  React.useEffect(() => {
    var url = pathname.split("/", 2);
    var link = url.length > 1 ? url[1] : url[0];
    setActiveLink(link);
  }, [pathname]);

  const onLogoutButtonClick = (event) => {
    event.preventDefault();
    userManager.signoutRedirect();
  };

  const onProfileButtonClick = () => {
    var redirect_uri = `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ""
    }`;
    window.location = `${process.env.REACT_APP_AUTHENTICATIONSRV_URL}Manage/Index?returnUrl=${redirect_uri}`;
  };

  return (
    <header className="page-head no-print">
      <div className="rd-navbar-wrap">
        <nav
          data-layout="rd-navbar-fixed"
          data-sm-layout="rd-navbar-fixed"
          data-md-device-layout="rd-navbar-fixed"
          data-lg-layout="rd-navbar-static"
          data-lg-device-layout="rd-navbar-static"
          data-stick-up-clone="false"
          data-md-stick-up-offset="53px"
          data-lg-stick-up-offset="53px"
          data-md-stick="true"
          data-lg-stick-up="true"
          className="rd-navbar rd-navbar-default"
        >
          <div className="rd-navbar-inner">
            <div className="rd-navbar-aside-wrap">
              <div className="rd-navbar-aside">
                <div data-rd-navbar-toggle=".rd-navbar-aside" className="rd-navbar-aside-toggle">
                  <span />
                </div>
                <div className="rd-navbar-aside-content">
                  <ul className="rd-navbar-aside-group list-units">
                    <li />
                    <li>
                      <div className="unit unit-horizontal unit-spacing-xs unit-middle">
                        <div className="unit-left">
                          <span className="icon icon-xxs icon-primary material-icons-phone" />
                        </div>
                        <div className="unit-body">
                          <a href="callto:#" className="link-dusty-gray">
                            +370 (5) 219 7601
                          </a>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="unit unit-horizontal unit-spacing-xs unit-middle">
                        <div className="unit-left">
                          <span className="icon icon-xxs icon-primary fa-envelope-o" />
                        </div>
                        <div className="unit-body">
                          <a href="mailto:info@ivp.lt" className="link-dusty-gray">
                            info@ivp.lt
                          </a>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="unit unit-horizontal unit-spacing-xs unit-middle no-wrap">
                        <div className="unit-body">
                          <span className="icon icon-xxs icon-primary fa-globe" />
                          <button
                            className={language === "lt" ? "lng-button active" : "lng-button"}
                            onClick={() => updateLanguage("lt")}
                          >
                            LT
                          </button>
                          <button
                            className={language === "en" ? "lng-button active" : "lng-button"}
                            onClick={() => updateLanguage("en")}
                          >
                            EN
                          </button>
                        </div>
                      </div>
                    </li>
                    {user && (
                      <li>
                        <div className="unit unit-horizontal unit-spacing-xs unit-middle">
                          <div className="unit-left">
                            <span className="icon icon-xxs icon-primary fa-envelope" />
                          </div>
                          <div className="unit-body">
                            <Link to="/pranesimai/gautos" className="link-secondary">
                              {strings["Žinutės"]}
                              {neskaityti ? <span className="span-red">{neskaityti}</span> : null}
                            </Link>
                          </div>
                        </div>
                      </li>
                    )}
                    {isInRole("Dokumentai", user) ? (
                      <li>
                        <div className="unit unit-horizontal unit-spacing-xs unit-middle">
                          <div className="unit-left">
                            <span className="icon icon-xxs icon-primary fa-file" />
                          </div>
                          <div className="unit-body">
                            <Link to="/dokumentai" className="link-secondary">
                              {strings["Formos ir dokumentai"]}
                            </Link>
                          </div>
                        </div>
                      </li>
                    ) : null}
                    {user && !user.expired ? (
                      <li>
                        <div className="unit unit-horizontal unit-spacing-xs unit-middle">
                          <div className="unit-left">
                            <span className="icon icon-xxs icon-primary fa-user" />
                          </div>
                          <div className="">
                            <button
                              onClick={onProfileButtonClick}
                              className="btn-link link-secondary"
                              style={{ cursor: "pointer" }}
                            >
                              {user.profile.name}
                            </button>
                          </div>
                        </div>
                      </li>
                    ) : (
                      <SignIn />
                    )}
                    {user && !user.expired ? (
                      <li>
                        <div className="unit unit-horizontal unit-spacing-xs unit-middle">
                          <div className="unit-left">
                            <span className="icon icon-xxs icon-primary fa-sign-out" />
                          </div>
                          <div className="">
                            <button
                              onClick={onLogoutButtonClick}
                              className="btn-link link-secondary"
                              style={{ cursor: "pointer" }}
                            >
                              {strings["Atsijungti"]}
                            </button>
                          </div>
                        </div>
                      </li>
                    ) : null}

                    {/* {user && !user.expired ? <Profile user={user} /> : <SignIn />} */}
                  </ul>
                  <div style={{ float: "right" }}>
                    <a href={zalosUrl} className="btn btn-link btn-x-sm" target="_blank" rel="noopener noreferrer">
                      {strings["Pranešti apie žalą"]}
                    </a>
                  </div>
                  <div className="rd-navbar-aside-group" />
                </div>
              </div>
            </div>
            <div className="rd-navbar-group">
              <div className="rd-navbar-panel">
                <button data-rd-navbar-toggle=".rd-navbar-nav-wrap" className="rd-navbar-toggle">
                  <span />
                </button>
                <a href="/" className="rd-navbar-brand brand">
                  <img src="/images/ivp_firminis_spalvotas.png" className="logo-image" alt="IVP Logo" />
                </a>
              </div>
              <div className="rd-navbar-nav-wrap">
                <div className="rd-navbar-nav-inner">
                  <ul className="rd-navbar-nav">
                    {isInRole("Polisai", user) ? (
                      <li className={activeLink === "polisai" ? "active" : ""}>
                        <Link to="/polisai">{["POLISAI"]}</Link>
                      </li>
                    ) : null}
                    {isInRole("Imokos", user) ? (
                      <li className={activeLink === "imokos" ? "active" : ""}>
                        <Link to="/imokos">{strings["ĮMOKOS"]}</Link>
                      </li>
                    ) : null}
                    {isInRole("Žalos", user) ? (
                      <li className={activeLink === "zalos" ? "active" : ""}>
                        <Link to="/zalos">{strings["ŽALOS"]}</Link>
                      </li>
                    ) : null}
                    {isInRole("Darbuotojai", user) ? (
                      <li className={activeLink === "darbuotojai" ? "active" : ""}>
                        <Link to="/darbuotojai">{strings["DARBUOTOJAI"]}</Link>
                      </li>
                    ) : null}
                    {isInRole("Transportas", user) ? (
                      <li className={activeLink === "transportas" ? "active" : ""}>
                        <Link to="/transportas">{strings["TRANSPORTAS"]}</Link>
                      </li>
                    ) : null}
                    {isInRole("Transportas", user) || isInRole("Darbuotojai", user) ? (
                      <li className={activeLink === "prasymai" ? "active" : ""}>
                        <Link to="/prasymai">{strings["PRAŠYMAI"]}</Link>
                      </li>
                    ) : null}
                  </ul>
                </div>
                {activeLink === "statistika" ? (
                  <div className="rd-navbar-nav-inner">
                    <ul className="rd-navbar-nav">
                      {isInRole("Statistika", user) ? (
                        <li
                          className={
                            location.pathname === "/statistika" || location.pathname === "/statistika/diagramos"
                              ? "active"
                              : ""
                          }
                        >
                          <Link to="/statistika/diagramos">{strings["Diagramos"]}</Link>
                        </li>
                      ) : null}
                      {isInRole("Zalos", user) ? (
                        <li className={location.pathname === "/statistika/rizikos" ? "active" : ""}>
                          <Link to="/statistika/rizikos">{strings["Rizikų matrica"]}</Link>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                ) : null}
                {(activeLink === "darbuotojai" ||
                  activeLink === "sveikata" ||
                  activeLink === "gyvybe" ||
                  activeLink === "apklausos" ||
                  activeLink === "naudos") &&
                  user && (
                    <div className="rd-navbar-nav-inner">
                      <ul className="rd-navbar-nav">
                        {isInRole("Darbuotojai", user) ? (
                          <li className={activeLink === "sveikata" ? "active" : ""}>
                            <Link to="/sveikata">{strings["SVEIKATOS DRAUDIMAS"]}</Link>
                          </li>
                        ) : null}
                        {isInRole("Gyvybe", user) ? (
                          <li className={activeLink === "gyvybe" ? "active" : ""}>
                            <Link to="/gyvybe">{strings["GYVYBĖS DRAUDIMAS (GD)"]}</Link>
                          </li>
                        ) : null}
                        {isInRole("Apklausos", user) ? (
                          <li className={activeLink === "apklausos" ? "active" : ""}>
                            <Link to="/apklausos">{strings["APKLAUSOS"]}</Link>
                          </li>
                        ) : null}
                        {isInRole("Naudos", user) ? (
                          <li className={activeLink === "naudos" ? "active" : ""}>
                            <Link to="/naudos">{strings["NAUDŲ MATRICA (HR)"]}</Link>
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  )}
                {activeLink === "pranesimai" && user && (
                  <div className="rd-navbar-nav-inner">
                    <ul className="rd-navbar-nav">
                      <li className={location.pathname === "/pranesimai/nauja" ? "active" : ""}>
                        <Link to="/pranesimai/nauja">Nauja žinutė</Link>
                      </li>
                      <li className={location.pathname === "/pranesimai/gautos" ? "active" : ""}>
                        <Link to="/pranesimai/gautos">Gautos žinutės</Link>
                      </li>
                      <li className={location.pathname === "/pranesimai/issiustos" ? "active" : ""}>
                        <Link to="/pranesimai/issiustos">Išsiųstos žinutės</Link>
                      </li>
                      <li className={location.pathname === "/pranesimai/archyvas" ? "active" : ""}>
                        <Link to="/pranesimai/archyvas">Archyvas</Link>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};
export default Header;
