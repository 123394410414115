import { 
    RECEIVED_NAUDU_DARBUOTOJU_GRUPES,
    REQUESTED_NAUDU_DARBUOTOJU_GRUPES,
    UPDATE_NAUDU_DARBUOTOJU_GRUPE
} from '../constants';

export default function naudos(state = { 
    isFetching: true, 
    shouldRefresh: false, 
    list: []
}, action) 
{
  switch (action.type) {
    case REQUESTED_NAUDU_DARBUOTOJU_GRUPES: 
        return Object.assign({}, state, { isFetching: true, shouldRefresh: false });
    case RECEIVED_NAUDU_DARBUOTOJU_GRUPES:
        return Object.assign({}, state, { isFetching: false, list: action.payload, shouldRefresh: false});
    case UPDATE_NAUDU_DARBUOTOJU_GRUPE:
        var l = [...state.list];
        var index = l.findIndex(f => f.grupeId = action.payload.grupeId);
        if(index > -1) l[index] = action.payload;
        return Object.assign({}, state, { isFetching: false, list: l, shouldRefresh: false});    
    default:
        return state;
  }
}