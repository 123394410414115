import { 
    RECEIVED_FAILAI,
    REQUESTED_FAILAI
} from '../constants';

export default function failai(state = { 
    isFetching: true, 
    shouldRefresh: false, 
    list: []
}, action) 
{
  switch (action.type) {
    case REQUESTED_FAILAI: 
        return Object.assign({}, state, { isFetching: true, shouldRefresh: false });
    case RECEIVED_FAILAI:
        return Object.assign({}, state, { isFetching: false, list: state.list.concat(action.payload), shouldRefresh: false});
    default:
        return state;
  }
}

