import {
    REQUEST_DRAUDIMO_RUSYS,
    RECEIVED_DRAUDIMO_RUSYS,
    REFRESH_DRAUDIMO_RUSYS,
    SET_DRAUDIMO_RUSIS_ID,
    SET_DRAUDIMO_RUSYS_PATH
} from '../constants';

export default function draudimoRusys(state = {
    draudimoRusis: null,
    isFetching: false,
    list: null,
    path: null,
    selection: null,
    shouldRefresh: false
}, action) {
    switch (action.type) {
        case REFRESH_DRAUDIMO_RUSYS:
            return Object.assign({}, state, {
                shouldRefresh: true
            });
        case REQUEST_DRAUDIMO_RUSYS:
            return Object.assign({}, state, {
                isFetching: true,
                shouldRefresh: false
            });
        case RECEIVED_DRAUDIMO_RUSYS:
            return Object.assign({}, state, {
                isFetching: false,
                list: action.payload,
                shouldRefresh: false
            });
        case SET_DRAUDIMO_RUSIS_ID:
            return Object.assign({}, state, {
                selection: action.payload
            });
        case SET_DRAUDIMO_RUSYS_PATH:
            return Object.assign({}, state, {
                path: action.payload
            });
        default:
            return state;
    }
}