import React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import { OidcProvider } from "redux-oidc";
import userManager from "./utils/userManager";
import { LanguageProvider } from './language';
import { fetchTranslations } from "./fetchTranslations";
import App from "./App";
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container); 
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <OidcProvider store={store} userManager={userManager}>
        <LanguageProvider fetchTranslations={fetchTranslations}>
          <App />
        </LanguageProvider>
      </OidcProvider>
    </BrowserRouter>
  </Provider>
);


// ReactDOM.render(
//   <Provider store={store}>
//     <BrowserRouter>
//       <OidcProvider store={store} userManager={userManager}>
//         <LanguageProvider fetchTranslations={fetchTranslations}>
//           <App />
//         </LanguageProvider>
//       </OidcProvider>
//     </BrowserRouter>
//   </Provider>,
//   document.getElementById('root')
// );
