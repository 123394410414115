import {
  POST_ZALA,
  PUT_ZALOS_FILTRAS,
  REQUEST_ZALA,
  REQUEST_ZALOS,
  RECEIVED_ZALA,
  RECEIVED_ZALOS,
  REFRESH_ZALA,
  SAVED_ZALA,
  SAVING_ZALA,
  SET_ZALOS_FAILAI,
  SET_ZALOS_FILTRAS
} from "../constants";

function zalos(
  state = {
    filtras: {},
    isFetching: false,
    shouldRefresh: false,
    isSaving: false,
    item: {},
    items: [],
    list: []
  },
  action
) {
  switch (action.type) {
    case POST_ZALA:
    case PUT_ZALOS_FILTRAS:
      return Object.assign({}, state, { filtras: action.payload });
    case REFRESH_ZALA:
      return Object.assign({}, state, {
        shouldRefresh: true
      });
    case REQUEST_ZALA:
    case REQUEST_ZALOS:
      return Object.assign({}, state, {
        isFetching: true,
        shouldRefresh: false
      });
    case RECEIVED_ZALA:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.items,
        list: action.list,
        shouldRefresh: false
      });
    case RECEIVED_ZALOS:
      return Object.assign({}, state, {
        isFetching: false,
        list: action.payload,
        items: action.payload,
        shouldRefresh: false
      });
    case SAVING_ZALA:
      return Object.assign({}, state, {
        isSaving: true
      });
    case SAVED_ZALA:
      return Object.assign({}, state, {
        isSaving: false
      });
    case SET_ZALOS_FAILAI:
      return Object.assign({}, state, {
        isFetching: false,
        list: action.payload.list,
        items: action.payload.items,
        shouldRefresh: false
      });
    case SET_ZALOS_FILTRAS:
      return Object.assign({}, state, {
        list: action.payload
      });
    default:
      return state;
  }
}

export default zalos;
