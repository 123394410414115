import { 
    SET_LANGUAGE_CODE
} from '../constants';

function language(state = { code: 'LT', resource: null }, action) {
  switch (action.type) {
    case SET_LANGUAGE_CODE:
        return Object.assign({}, state, { code: action.code, resource: action.resource });
    default:
        return state;
  }
}

export default language;