import { 
    PUT_DOKUMENTAI_FILTRAS,
    REQUEST_DOKUMENTAI, 
    RECEIVED_DOKUMENTAI, 
    REFRESH_DOKUMENTAI,
    SET_DOKUMENTAI_FILTRAS
} from '../constants';

export default function dokumentai(state = { isFetching: false, shouldRefresh: false, item: null, list: null }, action) {
  switch (action.type) {
    case PUT_DOKUMENTAI_FILTRAS:
        return Object.assign({}, state, { filtras: action.payload });
    case REFRESH_DOKUMENTAI:
        return Object.assign({}, state, { shouldRefresh: true });
    case REQUEST_DOKUMENTAI: 
        return Object.assign({}, state, { isFetching: true, shouldRefresh: false });
    case RECEIVED_DOKUMENTAI:
        return Object.assign({}, state, { isFetching: false, items: action.items, list: action.list, shouldRefresh: false});
    case SET_DOKUMENTAI_FILTRAS:
        return Object.assign({}, state, { list: action.payload });
    default:
        return state;
  }
}

