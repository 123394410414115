import {
    REQUEST_DRAUDEJAI_SHORT,
    RECEIVED_DRAUDEJAI_SHORT,
    REFRESH_DRAUDEJAI_SHORT,
    SET_DRAUDEJAI_PATH,
    SET_DRAUDEJAS_SELECTED,
    SET_DRAUDEJAS_SINGLE_SELECTED
} from '../constants';

export default function draudejai(state = {
    isFetching: false,
    shouldRefresh: false,
    item: null,
    list: null,
    path: null,
    selection: [],
    value: null,
}, action) {
    switch (action.type) {
        case REFRESH_DRAUDEJAI_SHORT:
            return Object.assign({}, state, {
                shouldRefresh: true
            });
        case REQUEST_DRAUDEJAI_SHORT:
            return Object.assign({}, state, {
                isFetching: true,
                shouldRefresh: false
            });
        case RECEIVED_DRAUDEJAI_SHORT:
            return Object.assign({}, state, {
                isFetching: false,
                list: action.payload,
                selection: action.payload.length === 1 ? action.payload : null,
                value: action.payload.length === 1 ? action.payload[0] : null,
                shouldRefresh: false
            });
        case SET_DRAUDEJAI_PATH:
            return Object.assign({}, state, {
                path: action.payload
            });
        case SET_DRAUDEJAS_SELECTED:
            return Object.assign({}, state, {
                selection: action.payload
            });
        case SET_DRAUDEJAS_SINGLE_SELECTED:
            return Object.assign({}, state, {
                value: action.payload
            });
        default:
            return state;
    }
}