import {
  createUserManager
} from "redux-oidc";

const userManagerConfig = {
  authority: process.env.REACT_APP_AUTHENTICATIONSRV_URL,
  automaticSilentRenew: true,
  client_id: "js",

  // this will allow all the OIDC protocol claims to be visible in the window. normally a client app
  // wouldn't care about them or want them taking up space
  filterProtocolClaims: false,

  // this will toggle if profile endpoint is used
  loadUserInfo: true,

  // if we choose to use popup window instead for logins
  popup_redirect_uri: process.env.REACT_APP_REDIRECT_URL + "/popup.html",
  popupWindowFeatures: "menubar=yes,location=yes,toolbar=yes,width=1200,height=800,left=100,top=100;resizable=yes",
  post_logout_redirect_uri: process.env.REACT_APP_REDIRECT_URL,
  redirect_uri: process.env.REACT_APP_REDIRECT_URL + "/callback",

  // these two will be done dynamically from the buttons clicked, but are
  // needed if you want to use the silent_renew
  //response_type: "token id_token",
  response_type: "code",

  // will revoke (reference) access tokens at logout time
  revokeAccessTokenOnSignout: true,
  scope: "openid profile email roles api1",

  // silent renew will get a new access_token via an iframe
  // just prior to the old access_token expiring (60 seconds prior)
  silent_redirect_uri: process.env.REACT_APP_REDIRECT_URL + "/silent",
};

const userManager = createUserManager(userManagerConfig);

export default userManager;