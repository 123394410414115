import { 
    RECEIVED_NAUDOS,
    REQUESTED_NAUDOS,
    UPDATE_NAUDA
} from '../constants';

export default function naudos(state = { 
    isFetching: true, 
    shouldRefresh: false, 
    list: []
}, action) 
{
  switch (action.type) {
    case REQUESTED_NAUDOS: 
        return Object.assign({}, state, { isFetching: true, shouldRefresh: false });
    case RECEIVED_NAUDOS:
        return Object.assign({}, state, { isFetching: false, list: action.payload, shouldRefresh: false});
    case UPDATE_NAUDA:
        var l = [...state.list];
        var index = l.findIndex(f => f.naudaId === action.payload.naudaId);
        if(index > -1) l[index] = action.payload;
        return Object.assign({}, state, { isFetching: false, list: l, shouldRefresh: false});    
    default:
        return state;
  }
}