import {
    RECEIVED_IMOKOS_ISMOKOS,
    RECEIVED_ISLAIDOS_PAGAL_RUSIS,
    REQUEST_IMOKOS_ISMOKOS,
    RECEIVED_IMOKOS_ISMOKOS_SU_DR,
    REQUEST_ISLAIDOS_PAGAL_RUSIS,
    REFRESH_DIAGRAMOS,
    SET_STATISTIKA_DRAUDEJAS_FILTRAS,
    SET_STATISTIKA_DRAUDIMO_RUSIS_FILTRAS,
    SET_STATISTIKA_METAI
} from '../constants';

export default function statistika(state = {
        draudejasId: null,
        draudimoRusisId: null,
        isFetchingImokos: false,
        isFetchingIslaidos: false,
        metai: "",
        shouldRefreshImokos: false,
        shouldRefreshIslaidos: false,
        imokosIsmokosNuostoliai: null,
        imokosIsmokosNuostoliaiSuDr: null,
        imokosPagalDraudimoRusis: null
    },
    action) {
    switch (action.type) {
        case REFRESH_DIAGRAMOS:
            return Object.assign({}, state, {
                shouldRefreshImokos: true,
                shouldRefreshIslaidos: true
            });
        case REQUEST_IMOKOS_ISMOKOS:
            return Object.assign({}, state, {
                isFetchingImokos: true,
                shouldRefreshImokos: false
            });
        case REQUEST_ISLAIDOS_PAGAL_RUSIS:
            return Object.assign({}, state, {
                isFetchingIslaidos: true,
                shouldRefreshIslaidos: false
            });
        case RECEIVED_IMOKOS_ISMOKOS:
            return Object.assign({}, state, {
                isFetchingImokos: false,
                imokosIsmokosNuostoliai: action.payload,
                shouldRefreshImokos: false
            });
        case RECEIVED_IMOKOS_ISMOKOS_SU_DR:
            return Object.assign({}, state, {
                isFetchingImokos: false,
                imokosIsmokosNuostoliaiSuDr: action.payload,
                shouldRefreshImokos: false
            });
        case RECEIVED_ISLAIDOS_PAGAL_RUSIS:
            return Object.assign({}, state, {
                isFetchingIslaidos: false,
                imokosPagalDraudimoRusis: action.payload,
                shouldRefreshImokos: false
            });
        case SET_STATISTIKA_DRAUDEJAS_FILTRAS:
            return Object.assign({}, state, {
                draudejasId: action.payload
            });
        case SET_STATISTIKA_DRAUDIMO_RUSIS_FILTRAS:
            return Object.assign({}, state, {
                draudimoRusisId: action.payload
            });
        case SET_STATISTIKA_METAI:
            return Object.assign({}, state, {
                metai: action.payload
            });
        default:
            return state;
    }
}