/* eslint-disable */
var $ = require("jquery");
window.jQuery = $;
var jQuery = window.jQuery;
/**
 * Global variables
 */
("use strict");

var userAgent = navigator.userAgent.toLowerCase(),
  initialDate = new Date(),
  $document = $(document),
  $window = $(window),
  $html = $("html"),
  isDesktop = $html.hasClass("desktop"),
  isIE =
    userAgent.indexOf("msie") !== -1
      ? parseInt(userAgent.split("msie")[1], 10)
      : userAgent.indexOf("trident") !== -1
        ? 11
        : userAgent.indexOf("edge") !== -1 ? 12 : false,
  isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  ),
  //isTouch = "ontouchstart" in window,

  plugins = {
    pointerEvents: isIE < 11 ? "js/pointer-events.min.js" : false,
    bootstrapTooltip: $("[data-toggle='tooltip']"),
    bootstrapTabs: $(".tabs"),
    bootstrapAccordions: $(".accordion"),
    rdParallax: $(".rd-parallax"),
    rdGoogleMaps: $(".rd-google-map"),
    rdNavbar: $(".rd-navbar"),
    mfp: $("[data-lightbox]").not('[data-lightbox="gallery"] [data-lightbox]'),
    mfpGallery: $('[data-lightbox^="gallery"]'),
    owl: $(".owl-carousel"),
    swiper: $(".swiper-slider"),
    counter: $(".counter"),
    twitterfeed: $(".twitter"),
    progressBar: $(".progress-bar-js"),
    isotope: $(".isotope"),
    countDown: $(".countdown"),
    popover: $('[data-toggle="popover"]'),
    dateCountdown: $(".DateCountdown"),
    statefulButton: $(".btn-stateful"),
    slick: $(".slick-slider"),
    viewAnimate: $(".view-animate"),
    selectFilter: $("select"),
    rdInputLabel: $(".form-label"),
    bootstrapDateTimePicker: $("[data-time-picker]"),
    customWaypoints: $("[data-custom-scroll-to]"),
    photoSwipeGallery: $("[data-photo-swipe-item]"),
    stepper: $("input[type='number']"),
    radio: $("input[type='radio']"),
    checkbox: $("input[type='checkbox']"),
    customToggle: $("[data-custom-toggle]"),
    rdMailForm: $(".rd-mailform"),
    regula: $("[data-constraints]"),
    pageLoader: $(".page-loader"),
    search: $(".rd-search"),
    searchResults: $(".rd-search-results"),
    imgZoom: $("[mag-thumb]"),
    pageTitles: $(".page-title")
  };

/**
 * Initialize All Scripts
 */
$document.ready(function() {
  var isNoviBuilder = window.xMode;

  /**
   * Adjustments for Safari on Mac
   */
  (function($) {
    if (
      navigator.userAgent.indexOf("Safari") !== -1 &&
      navigator.userAgent.indexOf("Mac") !== -1 &&
      navigator.userAgent.indexOf("Chrome") === -1
    ) {
      $("html").addClass("mac"); // provide a class for the safari-mac specific css to filter with
    }
  })(jQuery);

  // /**
  //  * getSwiperHeight
  //  * @description  calculate the height of swiper slider basing on data attr
  //  */
  // function getSwiperHeight(object, attr) {
  //   var val = object.attr("data-" + attr),
  //     dim;

  //   if (!val) {
  //     return undefined;
  //   }

  //   dim = val.match(/(px)|(%)|(vh)$/i);

  //   if (dim.length) {
  //     switch (dim[0]) {
  //       case "px":
  //         return parseFloat(val);
  //       case "vh":
  //         return $(window).height() * (parseFloat(val) / 100);
  //       case "%":
  //         return object.width() * (parseFloat(val) / 100);
  //     }
  //   } else {
  //     return undefined;
  //   }
  // }

  // /**
  //  * toggleSwiperInnerVideos
  //  * @description  toggle swiper videos on active slides
  //  */
  // function toggleSwiperInnerVideos(swiper) {
  //   var prevSlide = $(swiper.slides[swiper.previousIndex]),
  //     nextSlide = $(swiper.slides[swiper.activeIndex]),
  //     videos;

  //   prevSlide.find("video").each(function () {
  //     this.pause();
  //   });

  //   videos = nextSlide.find("video");
  //   if (videos.length) {
  //     videos.get(0).play();
  //   }
  // }

  // /**
  //  * toggleSwiperCaptionAnimation
  //  * @description  toggle swiper animations on active slides
  //  */
  // function toggleSwiperCaptionAnimation(swiper) {
  //   var prevSlide = $(swiper.container),
  //     nextSlide = $(swiper.slides[swiper.activeIndex]);

  //   prevSlide
  //     .find("[data-caption-animate]")
  //     .each(function () {
  //       var $this = $(this);
  //       $this
  //         .removeClass("animated")
  //         .removeClass($this.attr("data-caption-animate"))
  //         .addClass("not-animated");
  //     });

  //   nextSlide
  //     .find("[data-caption-animate]")
  //     .each(function () {
  //       var $this = $(this),
  //         delay = $this.attr("data-caption-delay");

  //       if (!isNoviBuilder) {
  //         setTimeout(function () {
  //           $this
  //             .removeClass("not-animated")
  //             .addClass($this.attr("data-caption-animate"))
  //             .addClass("animated");
  //         }, delay ? parseInt(delay) : 0);
  //       }else{
  //         $this
  //           .removeClass("not-animated")
  //       }
  //     });
  // }

  // /**
  //  * makeParallax
  //  * @description  create swiper parallax scrolling effect
  //  */
  // function makeParallax(el, speed, wrapper, prevScroll) {
  //   var scrollY = window.scrollY || window.pageYOffset;

  //   if (prevScroll != scrollY) {
  //     prevScroll = scrollY;
  //     el.addClass('no-transition');
  //     el[0].style['transform'] = 'translate3d(0,' + -scrollY * (1 - speed) + 'px,0)';
  //     el.height();
  //     el.removeClass('no-transition');

  //     if (el.attr('data-fade') === 'true') {
  //       var bound = el[0].getBoundingClientRect(),
  //         offsetTop = bound.top * 2 + scrollY,
  //         sceneHeight = wrapper.outerHeight(),
  //         sceneDevider = wrapper.offset().top + sceneHeight / 2.0,
  //         layerDevider = offsetTop + el.outerHeight() / 2.0,
  //         pos = sceneHeight / 6.0,
  //         opacity;
  //       if (sceneDevider + pos > layerDevider && sceneDevider - pos < layerDevider) {
  //         el[0].style["opacity"] = 1;
  //       } else {
  //         if (sceneDevider - pos < layerDevider) {
  //           opacity = 1 + ((sceneDevider + pos - layerDevider) / sceneHeight / 3.0 * 5);
  //         } else {
  //           opacity = 1 - ((sceneDevider - pos - layerDevider) / sceneHeight / 3.0 * 5);
  //         }
  //         el[0].style["opacity"] = opacity < 0 ? 0 : opacity > 1 ? 1 : opacity.toFixed(2);
  //       }
  //     }
  //   }

  //   requestAnimationFrame(function () {
  //     makeParallax(el, speed, wrapper, prevScroll);
  //   });
  // }

  /**
   * isScrolledIntoView
   * @description  check the element whas been scrolled into the view
   */
  function isScrolledIntoView(elem) {
    var $window = $(window);
    return (
      elem.offset().top + elem.outerHeight() >= $window.scrollTop() &&
      elem.offset().top <= $window.scrollTop() + $window.height()
    );
  }

  // /**
  //  * initOnView
  //  * @description  calls a function when element has been scrolled into the view
  //  */
  function lazyInit(element, func) {
    var $win = jQuery(window);
    $win.on("load scroll", function() {
      if (!element.hasClass("lazy-loaded") && isScrolledIntoView(element)) {
        func.call();
        element.addClass("lazy-loaded");
      }
    });
  }

  /**
   * Live Search
   * @description  create live search results
   */
  function liveSearch(options) {
    $("#" + options.live)
      .removeClass("cleared")
      .html();
    options.current++;
    options.spin.addClass("loading");
    $.get(
      handler,
      {
        s: decodeURI(options.term),
        liveSearch: options.live,
        dataType: "html",
        liveCount: options.liveCount,
        filter: options.filter,
        template: options.template
      },
      function(data) {
        options.processed++;
        var live = $("#" + options.live);
        if (
          options.processed === options.current &&
          !live.hasClass("cleared")
        ) {
          live.find("> #search-results").removeClass("active");
          live.html(data);
          setTimeout(function() {
            live.find("> #search-results").addClass("active");
          }, 50);
        }
        options.spin
          .parents(".rd-search")
          .find(".input-group-addon")
          .removeClass("loading");
      }
    );
  }

  /**
   * attachFormValidator
   * @description  attach form validation to elements
   */
  function attachFormValidator(elements) {
    for (var i = 0; i < elements.length; i++) {
      var o = $(elements[i]),
        v;
      o
        .addClass("form-control-has-validation")
        .after("<span class='form-validation'></span>");
      v = o.parent().find(".form-validation");
      if (v.is(":last-child")) {
        o.addClass("form-control-last-child");
      }
    }

    elements
      .on("input change propertychange blur", function(e) {
        var $this = $(this),
          results;

        if (e.type !== "blur") {
          if (!$this.parent().hasClass("has-error")) {
            return;
          }
        }

        if ($this.parents(".rd-mailform").hasClass("success")) {
          return;
        }

        results = $this.regula("validate");
        if (results.length) {
          for (i = 0; i < results.length; i++) {
            $this
              .siblings(".form-validation")
              .text(results[i].message)
              .parent()
              .addClass("has-error");
          }
        } else {
          $this
            .siblings(".form-validation")
            .text("")
            .parent()
            .removeClass("has-error");
        }
      })
      .regula("bind");
  }

  /**
   * isValidated
   * @description  check if all elemnts pass validation
   */
  function isValidated(elements) {
    var results,
      errors = 0;
    if (elements.length) {
      for (j = 0; j < elements.length; j++) {
        var $input = $(elements[j]);

        results = $input.regula("validate");
        if (results.length) {
          for (k = 0; k < results.length; k++) {
            errors++;
            $input
              .siblings(".form-validation")
              .text(results[k].message)
              .parent()
              .addClass("has-error");
          }
        } else {
          $input
            .siblings(".form-validation")
            .text("")
            .parent()
            .removeClass("has-error");
        }
      }

      return errors === 0;
    }
    return true;
  }

  /**
   * Init Bootstrap tooltip
   * @description  calls a function when need to init bootstrap tooltips
   */
  function initBootstrapTooltip(tooltipPlacement) {
    if (window.innerWidth < 599) {
      plugins.bootstrapTooltip.tooltip("destroy");
      plugins.bootstrapTooltip.tooltip({
        placement: "bottom"
      });
    } else {
      plugins.bootstrapTooltip.tooltip("destroy");
      plugins.bootstrapTooltip.tooltip({ placement: "right" });
      plugins.bootstrapTooltip.tooltip();
    }
  }

  /**
   * ChangeExternalButtons
   * @description Change active tab in responsive active tab by external buttons (next tab, prev tab)
   */
  function changeExternalButtons(respTabItem, direction) {
    var prev, next, activeItem;

    respTabItem.find(".resp-tabs-extertal-list li").removeClass("active");

    activeItem = respTabItem.find(".resp-tab-item.resp-tab-active");

    next = activeItem.next();
    if (!next.length) {
      next = respTabItem.find(".resp-tab-item:first-child()");
    }

    prev = activeItem.prev();
    if (!prev.length) {
      prev = respTabItem.find(".resp-tab-item:last-child()");
    }

    if (direction) {
      if (direction === "next") {
        next.trigger("click");
      } else {
        prev.trigger("click");
      }

      setTimeout(function() {
        changeExternalButtons(respTabItem);
      }, 10);
    }

    respTabItem
      .find(".resp-tab-external-prev li:nth-child(" + (prev.index() + 1) + ")")
      .addClass("active");
    respTabItem
      .find(".resp-tab-external-next li:nth-child(" + (next.index() + 1) + ")")
      .addClass("active");
  }

  /**
   * Copyright Year
   * @description  Evaluates correct copyright year
   */
  var o = $("#copyright-year");
  if (o.length) {
    o.text(initialDate.getFullYear());
  }

  // /**
  //  * IE Polyfills
  //  * @description  Adds some loosing functionality to IE browsers
  //  */
  // if (isIE) {
  //   if (isIE < 10) {
  //     $html.addClass("lt-ie-10");
  //   }

  //   if (isIE < 11) {
  //     if (plugins.pointerEvents) {
  //       $.getScript(plugins.pointerEvents)
  //         .done(function () {
  //           $html.addClass("ie-10");
  //           PointerEventsPolyfill.initialize({});
  //         });
  //     }
  //   }

  //   if (isIE === 11) {
  //     $("html").addClass("ie-11");
  //   }

  //   if (isIE === 12) {
  //     $("html").addClass("ie-edge");
  //   }
  // }

  /**
   * Bootstrap Tooltips
   * @description Activate Bootstrap Tooltips
   */
  if (plugins.bootstrapTooltip.length) {
    var tooltipPlacement = plugins.bootstrapTooltip.attr("data-placement");
    initBootstrapTooltip(tooltipPlacement);
    $(window).on("resize orientationchange", function() {
      initBootstrapTooltip(tooltipPlacement);
    });
  }

  /**
   * @module       Magnific Popup
   * @author       Dmitry Semenov
   * @see          http://dimsemenov.com/plugins/magnific-popup/
   * @version      v1.0.0
   */
  if (
    plugins.mfp.length > 0 ||
    (plugins.mfpGallery.length > 0 && isNoviBuilder !== "designMode")
  ) {
    if (plugins.mfp.length) {
      for (var i = 0; i < plugins.mfp.length; i++) {
        var mfpItem = plugins.mfp[i];

        $(mfpItem).magnificPopup({
          type: mfpItem.getAttribute("data-lightbox")
        });
      }
    }
    if (plugins.mfpGallery.length) {
      for (var i = 0; i < plugins.mfpGallery.length; i++) {
        var mfpGalleryItem = $(plugins.mfpGallery[i]).find("[data-lightbox]");

        for (var c = 0; c < mfpGalleryItem.length; c++) {
          $(mfpGalleryItem).addClass(
            "mfp-" + $(mfpGalleryItem).attr("data-lightbox")
          );
        }

        mfpGalleryItem.end().magnificPopup({
          delegate: "[data-lightbox]",
          type: "image",
          gallery: {
            enabled: true
          }
        });
      }
    }
  }

  /**
   * RD Google Maps
   * @description Enables RD Google Maps plugin
   */
  // var rdGoogleMaps = $(".rd-google-map");
  // if (rdGoogleMaps.length) {
  //   var i;

  //   $.getScript(
  //     "//maps.google.com/maps/api/js?key=AIzaSyAFeB0kVA6ouyJ_gEvFbMaefLy3cBCyRwo&sensor=false&libraries=geometry,places&v=3.7",
  //     function() {
  //       var head = document.getElementsByTagName("head")[0],
  //         insertBefore = head.insertBefore;

  //       head.insertBefore = function(newElement, referenceElement) {
  //         if (
  //           (newElement.href &&
  //             newElement.href.indexOf(
  //               "//fonts.googleapis.com/css?family=Roboto"
  //             ) != -1) ||
  //           newElement.innerHTML.indexOf("gm-style") != -1
  //         ) {
  //           return;
  //         }
  //         insertBefore.call(head, newElement, referenceElement);
  //       };

  //       function initGoogleMap() {
  //         var $this = $(this),
  //           styles = $this.attr("data-styles");

  //         $this.googleMap({
  //           styles: styles ? JSON.parse(styles) : [],
  //           onInit: function(map) {
  //             var inputAddress = $("#rd-google-map-address");

  //             if (inputAddress.length) {
  //               var input = inputAddress;
  //               var geocoder = new google.maps.Geocoder();
  //               var marker = new google.maps.Marker({
  //                 map: map,
  //                 icon: "images/gmap_marker.png"
  //               });
  //               var autocomplete = new google.maps.places.Autocomplete(
  //                 inputAddress[0]
  //               );
  //               autocomplete.bindTo("bounds", map);
  //               inputAddress.attr("placeholder", "");
  //               inputAddress.on("change", function() {
  //                 $("#rd-google-map-address-submit").trigger("click");
  //               });
  //               inputAddress.on("keydown", function(e) {
  //                 if (e.keyCode == 13) {
  //                   $("#rd-google-map-address-submit").trigger("click");
  //                 }
  //               });

  //               $("#rd-google-map-address-submit").on("click", function(e) {
  //                 e.preventDefault();
  //                 var address = input.val();
  //                 geocoder.geocode(
  //                   {
  //                     address: address
  //                   },
  //                   function(results, status) {
  //                     if (status == google.maps.GeocoderStatus.OK) {
  //                       var latitude = results[0].geometry.location.lat();
  //                       var longitude = results[0].geometry.location.lng();

  //                       map.setCenter(
  //                         new google.maps.LatLng(
  //                           parseFloat(latitude),
  //                           parseFloat(longitude)
  //                         )
  //                       );
  //                       marker.setPosition(
  //                         new google.maps.LatLng(
  //                           parseFloat(latitude),
  //                           parseFloat(longitude)
  //                         )
  //                       );
  //                     }
  //                   }
  //                 );
  //               });
  //             }
  //           }
  //         });
  //       }

  //       for (i = 0; i < rdGoogleMaps.length; i++) {
  //         if (isNoviBuilder !== "designMode") {
  //           lazyInit($(rdGoogleMaps[i]), initGoogleMap.bind(rdGoogleMaps[i]));
  //         } else {
  //           initGoogleMap.bind(rdGoogleMaps[i])();
  //         }
  //       }
  //     }
  //   );
  // }

  /**
   * Bootstrap Date time picker
   */
  if (plugins.bootstrapDateTimePicker.length) {
    var i;
    for (i = 0; i < plugins.bootstrapDateTimePicker.length; i++) {
      var $dateTimePicker = $(plugins.bootstrapDateTimePicker[i]);
      var options = {};

      options["format"] = "dddd DD MMMM YYYY - HH:mm";
      if ($dateTimePicker.attr("data-time-picker") === "date") {
        options["format"] = "dddd DD MMMM YYYY";
        options["minDate"] = new Date();
      } else if ($dateTimePicker.attr("data-time-picker") === "time") {
        options["format"] = "HH:mm";
      }

      options["time"] = $dateTimePicker.attr("data-time-picker") !== "date";
      options["date"] = $dateTimePicker.attr("data-time-picker") !== "time";
      options["shortTime"] = true;

      $dateTimePicker.bootstrapMaterialDatePicker(options);
    }
  }

  /**
   * RD Twitter Feed
   * @description Enables RD Twitter Feed plugin
   */
  if (plugins.twitterfeed.length > 0) {
    var i;
    for (i = 0; i < plugins.twitterfeed.length; i++) {
      var twitterfeedItem = plugins.twitterfeed[i];
      $(twitterfeedItem).RDTwitter({});
    }
  }

  /**
   * Select2
   * @description Enables select2 plugin
   */
  if (plugins.selectFilter.length) {
    var i;
    for (i = 0; i < plugins.selectFilter.length; i++) {
      var select = $(plugins.selectFilter[i]);

      select
        .select2({
          theme: select.attr("data-custom-theme")
            ? select.attr("data-custom-theme")
            : "bootstrap"
        })
        .next()
        .addClass(
          select
            .attr("class")
            .match(/(input-sm)|(input-lg)|($)/i)
            .toString()
            .replace(new RegExp(",", "g"), " ")
        );
    }
  }

  /**
   * Stepper
   * @description Enables Stepper Plugin
   */
  if (plugins.stepper.length) {
    plugins.stepper.stepper({
      labels: {
        up: "",
        down: ""
      }
    });
  }

  /**
   * Radio
   * @description Add custom styling options for input[type="radio"]
   */
  if (plugins.radio.length) {
    var i;
    for (i = 0; i < plugins.radio.length; i++) {
      var $this = $(plugins.radio[i]);
      $this
        .addClass("radio-custom")
        .after("<span class='radio-custom-dummy'></span>");
    }
  }

  /**
   * Checkbox
   * @description Add custom styling options for input[type="checkbox"]
   */
  if (plugins.checkbox.length) {
    var i;
    for (i = 0; i < plugins.checkbox.length; i++) {
      var $this = $(plugins.checkbox[i]);
      $this
        .addClass("checkbox-custom")
        .after("<span class='checkbox-custom-dummy'></span>");
    }
  }

  /**
   * Popovers
   * @description Enables Popovers plugin
   */
  if (plugins.popover.length) {
    if (window.innerWidth < 767) {
      plugins.popover.attr("data-placement", "bottom");
      plugins.popover.popover();
    } else {
      plugins.popover.popover();
    }
  }

  /**
   * jQuery Countdown
   * @description  Enable countdown plugin
   */
  if (plugins.countDown.length) {
    var i;
    for (i = 0; i < plugins.countDown.length; i++) {
      var countDownItem = plugins.countDown[i],
        d = new Date(),
        type = countDownItem.getAttribute("data-type"),
        time = countDownItem.getAttribute("data-time"),
        format = countDownItem.getAttribute("data-format"),
        settings = [];

      d.setTime(Date.parse(time)).toLocaleString();
      settings[type] = d;
      settings["format"] = format;
      $(countDownItem).countdown(settings);
    }
  }

  /**
   * TimeCircles
   * @description  Enable TimeCircles plugin
   */
  if (plugins.dateCountdown.length) {
    var i;
    for (i = 0; i < plugins.dateCountdown.length; i++) {
      var dateCountdownItem = $(plugins.dateCountdown[i]),
        time = {
          Days: {
            text: "Days",
            show: true,
            color: dateCountdownItem.attr("data-color")
              ? dateCountdownItem.attr("data-color")
              : "#f9f9f9"
          },
          Hours: {
            text: "Hours",
            show: true,
            color: dateCountdownItem.attr("data-color")
              ? dateCountdownItem.attr("data-color")
              : "#f9f9f9"
          },
          Minutes: {
            text: "Minutes",
            show: true,
            color: dateCountdownItem.attr("data-color")
              ? dateCountdownItem.attr("data-color")
              : "#f9f9f9"
          },
          Seconds: {
            text: "Seconds",
            show: true,
            color: dateCountdownItem.attr("data-color")
              ? dateCountdownItem.attr("data-color")
              : "#f9f9f9"
          }
        };

      dateCountdownItem.TimeCircles({
        color: dateCountdownItem.attr("data-color")
          ? dateCountdownItem.attr("data-color")
          : "rgba(247, 247, 247, 1)",
        animation: "smooth",
        bg_width: dateCountdownItem.attr("data-bg-width")
          ? dateCountdownItem.attr("data-bg-width")
          : 0.9,
        circle_bg_color: dateCountdownItem.attr("data-bg")
          ? dateCountdownItem.attr("data-bg")
          : "rgba(0, 0, 0, 1)",
        fg_width: dateCountdownItem.attr("data-width")
          ? dateCountdownItem.attr("data-width")
          : 0.03
      });

      $(window).on("load resize orientationchange", function() {
        if (window.innerWidth < 479) {
          dateCountdownItem
            .TimeCircles({
              time: {
                Days: {
                  text: "Days",
                  show: true,
                  color: dateCountdownItem.attr("data-color")
                    ? dateCountdownItem.attr("data-color")
                    : "#f9f9f9"
                },
                Hours: {
                  text: "Hours",
                  show: true,
                  color: dateCountdownItem.attr("data-color")
                    ? dateCountdownItem.attr("data-color")
                    : "#f9f9f9"
                },
                Minutes: {
                  text: "Minutes",
                  show: true,
                  color: dateCountdownItem.attr("data-color")
                    ? dateCountdownItem.attr("data-color")
                    : "#f9f9f9"
                },
                Seconds: {
                  text: "Seconds",
                  show: false,
                  color: dateCountdownItem.attr("data-color")
                    ? dateCountdownItem.attr("data-color")
                    : "#f9f9f9"
                }
              }
            })
            .rebuild();
        } else if (window.innerWidth < 767) {
          dateCountdownItem
            .TimeCircles({
              time: {
                Days: {
                  text: "Days",
                  show: true,
                  color: dateCountdownItem.attr("data-color")
                    ? dateCountdownItem.attr("data-color")
                    : "#f9f9f9"
                },
                Hours: {
                  text: "Hours",
                  show: true,
                  color: dateCountdownItem.attr("data-color")
                    ? dateCountdownItem.attr("data-color")
                    : "#f9f9f9"
                },
                Minutes: {
                  text: "Minutes",
                  show: true,
                  color: dateCountdownItem.attr("data-color")
                    ? dateCountdownItem.attr("data-color")
                    : "#f9f9f9"
                },
                Seconds: {
                  text: "",
                  show: false,
                  color: dateCountdownItem.attr("data-color")
                    ? dateCountdownItem.attr("data-color")
                    : "#f9f9f9"
                }
              }
            })
            .rebuild();
        } else {
          dateCountdownItem.TimeCircles({ time: time }).rebuild();
        }
      });
    }
  }

  /**
   * Bootstrap Buttons
   * @description  Enable Bootstrap Buttons plugin
   */
  if (plugins.statefulButton.length) {
    $(plugins.statefulButton).on("click", function() {
      var statefulButtonLoading = $(this).button("loading");

      setTimeout(function() {
        statefulButtonLoading.button("reset");
      }, 2000);
    });
  }

  // /**
  //  * Progress bar
  //  * @description  Enable progress bar
  //  */
  // if (plugins.progressBar.length) {
  //   var i,
  //     bar,
  //     type;

  //   for (i = 0; i < plugins.progressBar.length; i++) {
  //     var progressItem = plugins.progressBar[i];
  //     bar = null;

  //     if (progressItem.className.indexOf("progress-bar-horizontal") > -1) {
  //       type = 'Line';
  //     }

  //     if (progressItem.className.indexOf("progress-bar-radial") > -1) {
  //       type = 'Circle';
  //     }

  //     if (progressItem.getAttribute("data-stroke") && progressItem.getAttribute("data-value") && type) {
  //       bar = new ProgressBar[type](progressItem, {
  //         strokeWidth: Math.round(parseFloat(progressItem.getAttribute("data-stroke")) / progressItem.offsetWidth * 100),
  //         trailWidth: progressItem.getAttribute("data-trail") ? Math.round(parseFloat(progressItem.getAttribute("data-trail")) / progressItem.offsetWidth * 100) : 0,
  //         text: {
  //           value: progressItem.getAttribute("data-counter") === "true" ? '0' : null,
  //           className: 'progress-bar__body',
  //           style: null
  //         }
  //       });
  //       bar.svg.setAttribute('preserveAspectRatio', "none meet");
  //       if (type === 'Line') {
  //         bar.svg.setAttributeNS(null, "height", progressItem.getAttribute("data-stroke"));
  //       }

  //       bar.path.removeAttribute("stroke");
  //       bar.path.className.baseVal = "progress-bar__stroke";
  //       if (bar.trail) {
  //         bar.trail.removeAttribute("stroke");
  //         bar.trail.className.baseVal = "progress-bar__trail";
  //       }

  //       if (progressItem.getAttribute("data-easing") && !isIE) {
  //         $(document)
  //           .on("scroll", {"barItem": bar}, $.proxy(function (event) {
  //             var bar = event.data.barItem;
  //             var $this = $(this);

  //             if (isScrolledIntoView($this) && this.className.indexOf("progress-bar--animated") === -1) {
  //               this.className += " progress-bar--animated";
  //               bar.animate(parseInt($this.attr("data-value")) / 100.0, {
  //                 easing: $this.attr("data-easing"),
  //                 duration: $this.attr("data-duration") ? parseInt($this.attr("data-duration")) : 800,
  //                 step: function (state, b) {
  //                   if (b._container.className.indexOf("progress-bar-horizontal") > -1 ||
  //                     b._container.className.indexOf("progress-bar-vertical") > -1) {
  //                     b.text.style.width = Math.abs(b.value() * 100).toFixed(0) + "%"
  //                   }
  //                   b.setText(Math.abs(b.value() * 100).toFixed(0));
  //                 }
  //               });
  //             }
  //           }, progressItem))
  //           .trigger("scroll");
  //       } else {
  //         bar.set(parseInt($(progressItem).attr("data-value")) / 100.0);
  //         bar.setText($(progressItem).attr("data-value"));
  //         if (type === 'Line') {
  //           bar.text.style.width = parseInt($(progressItem).attr("data-value")) + "%";
  //         }
  //       }
  //     } else {
  //       console.error(progressItem.className + ": progress bar type is not defined");
  //     }
  //   }
  // }

  /**
   * UI To Top
   * @description Enables ToTop Button
   */
  if (isDesktop && !isNoviBuilder) {
    $().UItoTop({
      easingType: "easeOutQuart",
      containerClass: "ui-to-top fa fa-angle-up"
    });
  }

  /**
   * RD Navbar
   * @description Enables RD Navbar plugin
   */

  var rdNavbar = $(".rd-navbar");
  if (rdNavbar.length) {
    require(["./jquery.rd-navbar"], function() {
      rdNavbar.RDNavbar({
        stickUpClone:
          rdNavbar.attr("data-stick-up-clone") && !isNoviBuilder
            ? rdNavbar.attr("data-stick-up-clone") === "true"
            : false,
        responsive: {
          0: {
            stickUp: !isNoviBuilder
              ? rdNavbar.attr("data-stick-up") === "true"
              : false
          },
          768: {
            stickUp: !isNoviBuilder
              ? rdNavbar.attr("data-sm-stick-up") === "true"
              : false
          },
          992: {
            stickUp: !isNoviBuilder
              ? rdNavbar.attr("data-md-stick-up") === "true"
              : false
          },
          1200: {
            stickUp: !isNoviBuilder
              ? rdNavbar.attr("data-lg-stick-up") === "true"
              : false
          }
        }
      });
      if (rdNavbar.attr("data-body-class")) {
        document.body.className += " " + rdNavbar.attr("data-body-class");
      }
    });
  }

  /**
   * ViewPort Universal
   * @description Add class in viewport
   */
  if (plugins.viewAnimate.length) {
    var i;
    for (i = 0; i < plugins.viewAnimate.length; i++) {
      var $view = $(plugins.viewAnimate[i]).not(".active");
      $document
        .on(
          "scroll",
          $.proxy(function() {
            if (isScrolledIntoView(this)) {
              this.addClass("active");
            }
          }, $view)
        )
        .trigger("scroll");
    }
  }

  // /**
  //  * Swiper 3.1.7
  //  * @description  Enable Swiper Slider
  //  */
  // if (plugins.swiper.length) {
  //   var i;
  //   for (i = 0; i < plugins.swiper.length; i++) {
  //     var s = $(plugins.swiper[i]);
  //     var pag = s.find(".swiper-pagination"),
  //       next = s.find(".swiper-button-next"),
  //       prev = s.find(".swiper-button-prev"),
  //       bar = s.find(".swiper-scrollbar"),
  //       parallax = s.parents('.rd-parallax').length,
  //       swiperSlide = s.find(".swiper-slide");

  //     for (j = 0; j < swiperSlide.length; j++) {
  //       var $this = $(swiperSlide[j]),
  //         url;

  //       if (url = $this.attr("data-slide-bg")) {
  //         $this.css({
  //           "background-image": "url(" + url + ")",
  //           "background-size": "cover"
  //         })
  //       }
  //     }

  //     swiperSlide.end()
  //       .find("[data-caption-animate]")
  //       .addClass("not-animated")
  //       .end()
  //       .swiper({
  //         autoplay: s.attr('data-autoplay') !== "false" && !isNoviBuilder ? s.attr('data-autoplay')  : null,
  //         direction: s.attr('data-direction') ? s.attr('data-direction') : "horizontal",
  //         effect: s.attr('data-slide-effect') ? s.attr('data-slide-effect') : "slide",
  //         speed: s.attr('data-slide-speed') ? s.attr('data-slide-speed') : 600,
  //         keyboardControl: s.attr('data-keyboard') === "true",
  //         mousewheelControl: s.attr('data-mousewheel') === "true",
  //         mousewheelReleaseOnEdges: s.attr('data-mousewheel-release') === "true",
  //         nextButton: next.length ? next.get(0) : null,
  //         prevButton: prev.length ? prev.get(0) : null,
  //         pagination: pag.length ? pag.get(0) : null,
  //         paginationClickable: pag.length ? pag.attr("data-clickable") !== "false" : false,
  //         paginationBulletRender: pag.length ? pag.attr("data-index-bullet") === "true" ? function (index, className) {
  //           return '<span class="' + className + '">' + (index + 1) + '</span>';
  //         } : null : null,
  //         scrollbar: bar.length ? bar.get(0) : null,
  //         scrollbarDraggable: bar.length ? bar.attr("data-draggable") !== "false" : true,
  //         scrollbarHide: bar.length ? bar.attr("data-draggable") === "false" : false,
  //         loop: s.attr("data-loop") && !isNoviBuilder ? s.attr('data-loop') !== "false" : false,
  //         simulateTouch: s.attr('data-simulate-touch') && !isNoviBuilder ? s.attr('data-simulate-touch') === "true" : false,
  //         onTransitionStart: function (swiper) {
  //           toggleSwiperInnerVideos(swiper);
  //         },
  //         onTransitionEnd: function (swiper) {
  //           toggleSwiperCaptionAnimation(swiper);
  //         },
  //         onInit: function (swiper) {
  //           toggleSwiperInnerVideos(swiper);
  //           toggleSwiperCaptionAnimation(swiper);

  //           var swiperParalax = s.find(".swiper-parallax");

  //           for (var k = 0; k < swiperParalax.length; k++) {
  //             var $this = $(swiperParalax[k]),
  //               speed;

  //             if (parallax && !isIEBrows && !isMobile) {
  //               if (speed = $this.attr("data-speed")) {
  //                 makeParallax($this, speed, s, false);
  //               }
  //             }
  //           }
  //           $(window).on('resize', function () {
  //             swiper.update(true);
  //           })
  //         }
  //       });

  //     $(window)
  //       .on("resize", function () {
  //         var mh = getSwiperHeight(s, "min-height"),
  //           h = getSwiperHeight(s, "height");
  //         if (h) {
  //           s.css("height", mh ? mh > h ? mh : h : h);
  //         }
  //       })
  //       .trigger("resize");
  //   }
  // }

  // /**
  //  * Page loader
  //  * @description Enables Page loader
  //  */
  // if (plugins.pageLoader.length > 0) {
  //   $window.on("load", function() {
  //     setTimeout(function() {
  //       plugins.pageLoader.addClass("loaded");
  //       $window.trigger("resize");
  //     }, 200);
  //   });
  // }

  /**
   * RD Search
   * @description Enables search
   */
  if (plugins.search.length || plugins.searchResults) {
    var handler = "bat/rd-search.php";
    var defaultTemplate =
      '<h6 class="search_title"><a target="_top" href="#{href}" class="search_link">#{title}</a></h6>' +
      "<p>...#{token}...</p>" +
      '<p class="match"><em>Terms matched: #{count} - URL: #{href}</em></p>';
    var defaultFilter = "*.html";

    if (plugins.search.length) {
      for (i = 0; i < plugins.search.length; i++) {
        var searchItem = $(plugins.search[i]),
          options = {
            element: searchItem,
            filter: searchItem.attr("data-search-filter")
              ? searchItem.attr("data-search-filter")
              : defaultFilter,
            template: searchItem.attr("data-search-template")
              ? searchItem.attr("data-search-template")
              : defaultTemplate,
            live: searchItem.attr("data-search-live")
              ? searchItem.attr("data-search-live")
              : false,
            liveCount: searchItem.attr("data-search-live-count")
              ? parseInt(searchItem.attr("data-search-live-count"), 10)
              : 4,
            current: 0,
            processed: 0,
            timer: {}
          };

        if ($(".rd-navbar-search-toggle").length) {
          var toggle = $(".rd-navbar-search-toggle");
          toggle.on("click", function() {
            if (!$(this).hasClass("active")) {
              searchItem
                .find("input")
                .val("")
                .trigger("propertychange");
            }
          });
        }

        if (options.live) {
          var clearHandler = false;

          searchItem.find("input").on(
            "keyup input propertychange",
            $.proxy(
              function() {
                this.term = this.element
                  .find("input")
                  .val()
                  .trim();
                this.spin = this.element.find(".input-group-addon");

                clearTimeout(this.timer);

                if (this.term.length > 2) {
                  this.timer = setTimeout(liveSearch(this), 200);

                  if (clearHandler === false) {
                    clearHandler = true;

                    $("body").on("click", function(e) {
                      if ($(e.toElement).parents(".rd-search").length === 0) {
                        $("#rd-search-results-live")
                          .addClass("cleared")
                          .html("");
                      }
                    });
                  }
                } else if (this.term.length === 0) {
                  $("#" + this.live)
                    .addClass("cleared")
                    .html("");
                }
              },
              options,
              this
            )
          );
        }

        searchItem.submit(
          $.proxy(
            function() {
              $("<input />")
                .attr("type", "hidden")
                .attr("name", "filter")
                .attr("value", this.filter)
                .appendTo(this.element);
              return true;
            },
            options,
            this
          )
        );
      }
    }

    if (plugins.searchResults.length) {
      var regExp = /\?.*s=([^&]+)\&filter=([^&]+)/g;
      var match = regExp.exec(window.location.search);

      if (match != null) {
        $.get(
          handler,
          {
            s: decodeURI(match[1]),
            dataType: "html",
            filter: match[2],
            template: defaultTemplate,
            live: ""
          },
          function(data) {
            plugins.searchResults.html(data);
          }
        );
      }
    }
  }

  /**
   * Slick carousel
   * @description  Enable Slick carousel plugin
   */
  if (plugins.slick.length) {
    var i;
    for (i = 0; i < plugins.slick.length; i++) {
      var $slickItem = $(plugins.slick[i]);

      $slickItem
        .slick({
          slidesToScroll:
            parseInt($slickItem.attr("data-slide-to-scroll"), 10) || 1,
          asNavFor: $slickItem.attr("data-for") || false,
          dots: $slickItem.attr("data-dots") === "true",
          infinite: isNoviBuilder
            ? false
            : $slickItem.attr("data-loop") === "true",
          focusOnSelect: true,
          arrows: $slickItem.attr("data-arrows") === "true",
          swipe: $slickItem.attr("data-swipe") === "true",
          autoplay: $slickItem.attr("data-autoplay") === "true",
          vertical: $slickItem.attr("data-vertical") === "true",
          centerMode: $slickItem.attr("data-center-mode") === "true",
          centerPadding: $slickItem.attr("data-center-padding")
            ? $slickItem.attr("data-center-padding")
            : "0.50",
          mobileFirst: true,
          responsive: [
            {
              breakpoint: 0,
              settings: {
                slidesToShow: parseInt($slickItem.attr("data-items"), 10) || 1
              }
            },
            {
              breakpoint: 479,
              settings: {
                slidesToShow:
                  parseInt($slickItem.attr("data-xs-items"), 10) || 1
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow:
                  parseInt($slickItem.attr("data-sm-items"), 10) || 1
              }
            },
            {
              breakpoint: 991,
              settings: {
                slidesToShow:
                  parseInt($slickItem.attr("data-md-items"), 10) || 1
              }
            },
            {
              breakpoint: 1199,
              settings: {
                slidesToShow:
                  parseInt($slickItem.attr("data-lg-items"), 10) || 1
              }
            }
          ]
        })
        .on("afterChange", function(event, slick, currentSlide, nextSlide) {
          var $this = $(this),
            childCarousel = $this.attr("data-child");

          if (childCarousel) {
            $(childCarousel + " .slick-slide").removeClass("slick-current");
            $(childCarousel + " .slick-slide")
              .eq(currentSlide)
              .addClass("slick-current");
          }
        });
    }
  }

  /**
   * Owl carousel
   * @description Enables Owl carousel plugin
   */
  if (plugins.owl.length) {
    var i;
    for (i = 0; i < plugins.owl.length; i++) {
      var c = $(plugins.owl[i]),
        responsive = {};

      var aliaces = ["-", "-xs-", "-sm-", "-md-", "-lg-"],
        values = [0, 480, 768, 992, 1200],
        j,
        k;

      for (j = 0; j < values.length; j++) {
        responsive[values[j]] = {};
        for (k = j; k >= -1; k--) {
          if (
            !responsive[values[j]]["items"] &&
            c.attr("data" + aliaces[k] + "items")
          ) {
            responsive[values[j]]["items"] =
              k < 0 ? 1 : parseInt(c.attr("data" + aliaces[k] + "items"), 10);
          }
          if (
            !responsive[values[j]]["stagePadding"] &&
            responsive[values[j]]["stagePadding"] !== 0 &&
            c.attr("data" + aliaces[k] + "stage-padding")
          ) {
            responsive[values[j]]["stagePadding"] =
              k < 0
                ? 0
                : parseInt(c.attr("data" + aliaces[k] + "stage-padding"), 10);
          }
          if (
            !responsive[values[j]]["margin"] &&
            responsive[values[j]]["margin"] !== 0 &&
            c.attr("data" + aliaces[k] + "margin")
          ) {
            responsive[values[j]]["margin"] =
              k < 0 ? 30 : parseInt(c.attr("data" + aliaces[k] + "margin"), 10);
          }
        }
      }

      // Create custom Pagination
      if (c.attr("data-dots-custom")) {
        c.on("initialized.owl.carousel", function(event) {
          var carousel = $(event.currentTarget),
            customPag = $(carousel.attr("data-dots-custom")),
            active = 0;

          if (carousel.attr("data-active")) {
            active = parseInt(carousel.attr("data-active"), 10);
          }

          carousel.trigger("to.owl.carousel", [active, 300, true]);
          customPag.find("[data-owl-item='" + active + "']").addClass("active");

          customPag.find("[data-owl-item]").on("click", function(e) {
            e.preventDefault();
            carousel.trigger("to.owl.carousel", [
              parseInt(this.getAttribute("data-owl-item"), 10),
              300,
              true
            ]);
          });

          carousel.on("translate.owl.carousel", function(event) {
            customPag.find(".active").removeClass("active");
            customPag
              .find("[data-owl-item='" + event.item.index + "']")
              .addClass("active");
          });
        });
      }

      // Create custom Numbering
      if (typeof c.attr("data-numbering") !== "undefined") {
        var numberingObject = $(c.attr("data-numbering"));

        c.on(
          "initialized.owl.carousel changed.owl.carousel",
          (function(numberingObject) {
            return function(e) {
              if (!e.namespace) return;
              numberingObject.find(".numbering-current").text(e.item.index + 1);
              numberingObject.find(".numbering-count").text(e.item.count);
            };
          })(numberingObject)
        );
      }

      c.owlCarousel({
        autoplay: c.attr("data-autoplay") === "true",
        loop:
          isNoviBuilder === "designMode"
            ? false
            : c.attr("data-loop") === "true",
        items: 1,
        dotsContainer: c.attr("data-pagination-class") || false,
        navContainer: c.attr("data-navigation-class") || false,
        mouseDrag:
          isNoviBuilder === "designMode"
            ? false
            : c.attr("data-mouse-drag") !== "false",
        nav: c.attr("data-nav") === "true",
        dots: c.attr("data-dots") === "true",
        dotsEach: c.attr("data-dots-each")
          ? parseInt(c.attr("data-dots-each"), 10)
          : false,
        animateIn: c.attr("data-animation-in")
          ? c.attr("data-animation-in")
          : "slide",
        animateOut: c.attr("data-animation-out")
          ? c.attr("data-animation-out")
          : false,
        responsive: responsive,
        navText: []
      });
    }
  }

  /**
   * jQuery Count To
   * @description Enables Count To plugin
   */

  var counter = $(".counter");
  if (counter.length) {
    require(["./countTo"], function() {
      var i;

      for (i = 0; i < counter.length; i++) {
        var $counterNotAnimated = $(counter[i]).not(".animated");
        $document
          .on(
            "scroll",
            $.proxy(function() {
              var $this = this;

              if (!$this.hasClass("animated") && isScrolledIntoView($this)) {
                $this.countTo({
                  refreshInterval: 40,
                  from: 0,
                  to: parseInt($this.text(), 10),
                  speed: $this.attr("data-speed") || 1000,
                  formatter: function(value, options) {
                    value = value.toFixed(options.decimals);
                    if (value < 10) {
                      return "0" + value;
                    }
                    return value;
                  }
                });
                $this.addClass("animated");
              }
            }, $counterNotAnimated)
          )
          .trigger("scroll");
      }
    });
  }

  // /**
  //  * Isotope
  //  * @description Enables Isotope plugin
  //  */
  // if (plugins.isotope.length) {
  //   var i, j, isogroup = [];
  //   for (i = 0; i < plugins.isotope.length; i++) {
  //     var isotopeItem = plugins.isotope[i],
  //       filterItems = $(isotopeItem).closest('.isotope-wrap').find('[data-isotope-filter]'),
  //       iso;

  //     iso = new Isotope(isotopeItem, {
  //       itemSelector: '.isotope-item',
  //       layoutMode: isotopeItem.getAttribute('data-isotope-layout') ? isotopeItem.getAttribute('data-isotope-layout') : 'masonry',
  //       filter: '*',
  //       masonry: {
  //         columnWidth: 0.42
  //       }
  //     });

  //     isogroup.push(iso);

  //     filterItems.on("click", function (e) {
  //       e.preventDefault();
  //       var filter = $(this),
  //         iso = $('.isotope[data-isotope-group="' + this.getAttribute("data-isotope-group") + '"]'),
  //         filtersContainer = filter.closest(".isotope-filters");

  //       filtersContainer
  //         .find('.active')
  //         .removeClass("active");
  //       filter.addClass("active");

  //       iso.isotope({
  //         itemSelector: '.isotope-item',
  //         layoutMode: iso.attr('data-isotope-layout') ? iso.attr('data-isotope-layout') : 'masonry',
  //         filter: this.getAttribute("data-isotope-filter") == '*' ? '*' : '[data-filter*="' + this.getAttribute("data-isotope-filter") + '"]',
  //         masonry: {
  //           columnWidth: 0.42
  //         }
  //       });

  //       $window.trigger('resize');

  //     }).eq(0).trigger("click");
  //   }

  //   $(window).on('load', function () {
  //     setTimeout(function () {
  //       var i;
  //       for (i = 0; i < isogroup.length; i++) {
  //         isogroup[i].element.className += " isotope--loaded";
  //         isogroup[i].layout();
  //       }
  //     }, 600);
  //   });
  // }

  // /**
  //  * WOW
  //  * @description Enables Wow animation plugin
  //  */
  // if (!isNoviBuilder) {
  //   if (isDesktop && $html.hasClass("wow-animation") && $(".wow").length) {
  //     new WOW().init();
  //   }
  // }

  /**
   * Bootstrap tabs
   * @description Activate Bootstrap Tabs
   */
  if (plugins.bootstrapTabs.length) {
    var i;
    for (i = 0; i < plugins.bootstrapTabs.length; i++) {
      var bootstrapTabsItem = $(plugins.bootstrapTabs[i]);

      bootstrapTabsItem.on("click", "a", function(event) {
        event.preventDefault();
        $(this).tab("show");
      });
    }
  }

  /**
   * RD Input Label
   * @description Enables RD Input Label Plugin
   */
  if (plugins.rdInputLabel.length) {
    plugins.rdInputLabel.RDInputLabel();
  }

  /**
   * Regula
   * @description Enables Regula plugin
   */
  if (plugins.regula.length) {
    attachFormValidator(plugins.regula);
  }

  /**
   * RD Mailform
   */
  if (plugins.rdMailForm.length) {
    var i,
      j,
      k,
      msg = {
        MF000: "Successfully sent!",
        MF001: "Recipients are not set!",
        MF002: "Form will not work locally!",
        MF003: "Please, define email field in your form!",
        MF004: "Please, define type of your form!",
        MF254: "Something went wrong with PHPMailer!",
        MF255: "Aw, snap! Something went wrong."
      };

    for (i = 0; i < plugins.rdMailForm.length; i++) {
      var $form = $(plugins.rdMailForm[i]);

      $form.attr("novalidate", "novalidate").ajaxForm({
        data: {
          "form-type": $form.attr("data-form-type") || "contact",
          counter: i
        },
        beforeSubmit: function() {
          if (!isNoviBuilder) {
            var form = $(plugins.rdMailForm[this.extraData.counter]);
            var inputs = form.find("[data-constraints]");
            if (isValidated(inputs)) {
              var output = $("#" + form.attr("data-form-output"));

              if (output.hasClass("snackbars")) {
                output.html(
                  '<p><span class="icon text-middle fa fa-circle-o-notch fa-spin icon-xxs"></span><span>Sending</span></p>'
                );
                output.addClass("active");
              }
            } else {
              return false;
            }
          }
        },
        error: function(result) {
          if (!isNoviBuilder) {
            var output = $(
              "#" +
                $(plugins.rdMailForm[this.extraData.counter]).attr(
                  "data-form-output"
                )
            );
            output.text(msg[result]);
          }
        },
        success: function(result) {
          if (!isNoviBuilder) {
            var form = $(plugins.rdMailForm[this.extraData.counter]),
              output = $("#" + form.attr("data-form-output")),
              $select = form.find("select");

            // Clear select2 after submit form
            if ($select.length) {
              for (j = 0; j < $select.length; j++) {
                var $selectitem = $($select[j]);
                $selectitem.select2("val", null);
              }
            }

            form.addClass("success");
            result = result.length === 5 ? result : "MF255";
            output.text(msg[result]);

            if (result === "MF000") {
              if (output.hasClass("snackbars")) {
                output.html(
                  '<p><span class="icon text-middle mdi mdi-check icon-xxs"></span><span>' +
                    msg[result] +
                    "</span></p>"
                );
              }
              output.addClass("success active");
            } else {
              if (output.hasClass("snackbars")) {
                output.html(
                  ' <p class="snackbars-left"><span class="icon icon-xxs mdi mdi-alert-outline text-middle"></span><span>' +
                    msg[result] +
                    "</span></p>"
                );
              }
              output.addClass("error active");
            }

            form.clearForm();
            form.find("input, textarea").blur();

            setTimeout(function() {
              output.removeClass("active error success");
              form.removeClass("success");
            }, 5000);
          }
        }
      });
    }
  }

  // /**
  //  * PhotoSwipe Gallery
  //  * @description Enables PhotoSwipe Gallery plugin
  //  */
  // if (plugins.photoSwipeGallery.length && !isNoviBuilder) {

  //   // init image click event
  //   $document.delegate("[data-photo-swipe-item]", "click", function (event) {
  //     event.preventDefault();

  //     var $el = $(this),
  //       $galleryItems = $el.parents("[data-photo-swipe-gallery]").find("a[data-photo-swipe-item]"),
  //       pswpElement = document.querySelectorAll('.pswp')[0],
  //       encounteredItems = {},
  //       pswpItems = [],
  //       options,
  //       pswpIndex = 0,
  //       pswp;

  //     if ($galleryItems.length == 0) {
  //       $galleryItems = $el;
  //     }

  //     // loop over the gallery to build up the photoswipe items
  //     $galleryItems.each(function () {
  //       var $item = $(this),
  //         src = $item.attr('href'),
  //         size = $item.attr('data-size').split('x'),
  //         pswdItem;

  //       if ($item.is(':visible')) {

  //         // if we have this image the first time
  //         if (!encounteredItems[src]) {
  //           // build the photoswipe item
  //           pswdItem = {
  //             src: src,
  //             w: parseInt(size[0], 10),
  //             h: parseInt(size[1], 10),
  //             el: $item // save link to element for getThumbBoundsFn
  //           };

  //           // store that we already had this item
  //           encounteredItems[src] = {
  //             item: pswdItem,
  //             index: pswpIndex
  //           };

  //           // push the item to the photoswipe list
  //           pswpItems.push(pswdItem);
  //           pswpIndex++;
  //         }
  //       }
  //     });

  //     options = {
  //       index: encounteredItems[$el.attr('href')].index,

  //       getThumbBoundsFn: function (index) {
  //         var $el = pswpItems[index].el,
  //           offset = $el.offset();

  //         return {
  //           x: offset.left,
  //           y: offset.top,
  //           w: $el.width()
  //         };
  //       }
  //     };

  //     // open the photoswipe gallery
  //     pswp = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, pswpItems, options);
  //     pswp.init();
  //   });
  // }

  /**
   * Custom Toggles
   */
  if (plugins.customToggle.length) {
    var i;

    for (i = 0; i < plugins.customToggle.length; i++) {
      var $this = $(plugins.customToggle[i]);

      $this.on(
        "click",
        $.proxy(function(event) {
          event.preventDefault();
          var $ctx = $(this);
          $($ctx.attr("data-custom-toggle"))
            .add(this)
            .toggleClass("active");
        }, $this)
      );

      if ($this.attr("data-custom-toggle-hide-on-blur") === "true") {
        $("body").on("click", $this, function(e) {
          if (
            e.target !== e.data[0] &&
            $(e.data.attr("data-custom-toggle")).find($(e.target)).length &&
            e.data.find($(e.target)).length === 0
          ) {
            $(e.data.attr("data-custom-toggle"))
              .add(e.data[0])
              .removeClass("active");
          }
        });
      }

      if ($this.attr("data-custom-toggle-disable-on-blur") === "true") {
        $("body").on("click", $this, function(e) {
          if (
            e.target !== e.data[0] &&
            $(e.data.attr("data-custom-toggle")).find($(e.target)).length ===
              0 &&
            e.data.find($(e.target)).length === 0
          ) {
            $(e.data.attr("data-custom-toggle"))
              .add(e.data[0])
              .removeClass("active");
          }
        });
      }
    }
  }

  /**
   * Magnificent image zoom
   */
  if (plugins.imgZoom.length) {
    var i;
    for (i = 0; i < plugins.imgZoom.length; i++) {
      var $imgZoomItem = $(plugins.imgZoom[i]);
      $imgZoomItem.mag();
    }
  }

  /**
   * Custom Waypoints
   */
  if (isNoviBuilder !== "designMode") {
    if (plugins.customWaypoints.length) {
      var i;
      for (i = 0; i < plugins.customWaypoints.length; i++) {
        var $this = $(plugins.customWaypoints[i]);

        $this.on("click", function(e) {
          e.preventDefault();
          $("body, html")
            .stop()
            .animate(
              {
                scrollTop: $(
                  "#" + $(this).attr("data-custom-scroll-to")
                ).offset().top
              },
              1000,
              function() {
                $(window).trigger("resize");
              }
            );
        });
      }
    }
  }

  /**
   * RD Parallax
   * @description Enables RD Parallax plugin
   */
  if (isNoviBuilder !== "designMode") {
    var rdParallax = $(".rd-parallax");
    if (rdParallax.length && !isMobile) {
      var i;
      require(["./jquery.rd-parallax"], function() {
        $.RDParallax();

        if (!isIE && !isMobile) {
          $(window).on("scroll", function() {
            for (i = 0; i < rdParallax.length; i++) {
              var parallax = $(rdParallax[i]);
              if (isScrolledIntoView(parallax)) {
                parallax.find(".rd-parallax-inner").css("position", "fixed");
              } else {
                parallax.find(".rd-parallax-inner").css("position", "absolute");
              }
            }
          });
        }

        $("a[href='#']").on("click", function(event) {
          setTimeout(function() {
            $(window).trigger("resize");
          }, 300);
        });
      });
    }
  }

  /**
   * Page title
   * @description Enables page-title plugin
   */
  if (isNoviBuilder !== "designMode") {
    if (plugins.pageTitles) {
      var varCount = 30;

      for (var i = 0; i < plugins.pageTitles.length; i++) {
        var pageTitle = $(plugins.pageTitles[i]);

        var header = pageTitle.children()[0];
        var wrapper = $(document.createElement("div"));
        wrapper.addClass("page-title-inner");

        var pageTitleLeft = $(document.createElement("div")),
          pageTitleCenter = $(document.createElement("div")),
          pageTitleRight = $(document.createElement("div"));

        pageTitleLeft.addClass("page-title-left");
        pageTitleCenter.addClass("page-title-center");
        pageTitleRight.addClass("page-title-right");

        for (var j = 0; j < varCount; j++) {
          pageTitleLeft.append(header.cloneNode(true));
          pageTitleRight.append(header.cloneNode(true));
        }

        pageTitleCenter.append(header.cloneNode(true));
        pageTitle.children(0).remove();

        wrapper.append(pageTitleLeft);
        wrapper.append(pageTitleCenter);
        wrapper.append(pageTitleRight);

        pageTitle.append(wrapper);
      }
    }
  }
});
