import { 
    //POST_TURTAS, 
    REQUEST_TURTAS_ITEM, 
    REQUEST_TURTAS_LIST, 
    RECEIVED_TURTAS_ITEM, 
    RECEIVED_TURTAS_LIST, 
    REFRESH_TURTAS_LIST } from '../constants';

function turtas(state = { isFetching: false, shouldRefresh: false, item: {}, list: [] }, action) {
  switch (action.type) {
    case REFRESH_TURTAS_LIST:
        return Object.assign({}, state, { shouldRefresh: true });
    case REQUEST_TURTAS_ITEM: 
    case REQUEST_TURTAS_LIST: 
        return Object.assign({}, state, { isFetching: true, shouldRefresh: false });
    case RECEIVED_TURTAS_ITEM:
        return Object.assign({}, state, { isFetching: false, item: action.payload, shouldRefresh: false });
    case RECEIVED_TURTAS_LIST:
        return Object.assign({}, state, { isFetching: false, list: action.payload, shouldRefresh: false});
    default:
        return state;
  }
}

export default turtas;