import store from "../store";
import {
  UserException
} from "./userException";

// a request helper which reads the access_token from the redux state and passes it in its HTTP request
export default function apiRequest(url, method = "GET", body, params, cache = "default") {
  var state = store.getState();
  const token = state.oidc.user ? state.oidc.user.access_token : "";
  const headers = new Headers();
  headers.append("Accept", "application/json");
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);

  const options = {
    method,
    headers,
    body,
    cache
  };

  var apiUrl = process.env.REACT_APP_API_URL;

  var url1 = new URL(apiUrl + url);
  if (params) {
    Object.keys(params).forEach(key => {
      var value = params[key];
      if (value && value.length && Array.isArray(value)) {
        value.forEach(i => url1.searchParams.append(key, i));
      } else {
        url1.searchParams.append(key, value);
      }
    });
  }

  return fetch(url1, options)
    .then(function (res) {
      if (res.status === 200) {
        return res.json();
      }
      if (res.status === 204) {
        return;
      }
      if (res.status === 401) {
        throw new UserException(res.status, "Prisijunkite iš naujo.");
      }
      if (res.status === 403) {
        throw new UserException(res.status, "Draudžiama prieiga");
      }
      return res.json().then(function (error) {
        throw new UserException(res.status, error.message ?? error);
      });
    })
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      throw error;
    });
}