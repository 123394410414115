import { combineReducers } from 'redux';
import darbuotojaiReducer from './darbuotojaiReducer';
import dokumentaiReducer from './dokumentaiReducer';
import draudejaiReducer from './draudejaiReducer';
import draudikaiReducer from './draudikaiReducer';
import draudimoRusysReducer from './draudimoRusysReducer';
import failaiReducer from './failaiReducer';
import imokosReducer from './imokosReducer';
import kontaktaiUzklausaReducer from './kontaktaiUzklausaReducer';
import language from './languageReducer';
import {reducer as oidcReducer} from 'redux-oidc';
import naudos from './naudosReducer';
import nauduDarbuotojaiReducer from "./nauduDarbuotojaiReducer";
import nauduDarbuotojuGrupesReducer from "./NauduDarbuotojuGrupesReducer";
import polisaiReducer from './polisaiReducer';
import pranesimaiReducer from './pranesimaiReducer';
import rizikosReducer from './rizikosReducer';
import sidebarReducer from './sidebarReducer';
import statistikaReducer from './statistikaReducer';
import transportasReducer from './tansportasReducer';
import turtasReducer from './turtasReducer';
import zalosReducer from './zalosReducer';


const rootReducer = combineReducers({
  darbuotojai: darbuotojaiReducer,
  dokumentai: dokumentaiReducer,
  draudejai: draudejaiReducer,
  draudikai: draudikaiReducer,
  draudimoRusys: draudimoRusysReducer,
  failai: failaiReducer,
  imokos: imokosReducer,
  kontaktai: kontaktaiUzklausaReducer,
  language: language,
  naudos: naudos,
  nauduDarbuotojai: nauduDarbuotojaiReducer,
  nauduDarbuotojuGrupes: nauduDarbuotojuGrupesReducer,
  oidc: oidcReducer,
  polisai: polisaiReducer,
  pranesimai: pranesimaiReducer,
  rizikos: rizikosReducer,
  sidebar: sidebarReducer,
  statistika: statistikaReducer,
  transportas: transportasReducer,
  turtas: turtasReducer,
  zalos: zalosReducer
})

export default rootReducer