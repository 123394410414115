import {
  IMPORTED_DARBUOTOJAI,
  IMPORTING_DARBUOTOJAI,
  PUT_DARBUOTOJAI_FILTRAS,
  REQUEST_DARBUOTOJAI,
  RECEIVED_DARBUOTOJAI,
  RECEIVED_DARBUOTOJAI_SHORT,
  RECEIVED_DARBUOTOJAS,
  REFRESH_DARBUOTOJAI,
  SET_DARBUOTOJAI_FILTRAS,
  UPDATE_DARBUOTOJAI,
  UPDATE_SORT
} from "../constants";

export default function darbuotojai(
  state = {
    isFetching: false,
    shouldRefresh: false,
    items: null,
    list: null,
    item: null,
    sort: null
  },
  action
) {
  switch (action.type) {
    case IMPORTING_DARBUOTOJAI:
      return Object.assign({}, state, {
        isImporting: true
      });
    case IMPORTED_DARBUOTOJAI:
      return Object.assign({}, state, {
        pranesimai: action.pranesimai,
        isImporting: false
      });
    case PUT_DARBUOTOJAI_FILTRAS:
      return Object.assign({}, state, {
        filtras: action.payload
      });
    case REFRESH_DARBUOTOJAI:
      return Object.assign({}, state, {
        shouldRefresh: true
      });
    case REQUEST_DARBUOTOJAI:
      return Object.assign({}, state, {
        isFetching: true,
        shouldRefresh: false
      });
    case RECEIVED_DARBUOTOJAI:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.items,
        list: action.list,
        shouldRefresh: false
      });
    case RECEIVED_DARBUOTOJAI_SHORT:
      return Object.assign({}, state, {
        darbuotojai: action.payload
      });
    case RECEIVED_DARBUOTOJAS:
      return Object.assign({}, state, {
        isFetching: false,
        item: action.item
      });
    case SET_DARBUOTOJAI_FILTRAS:
      return Object.assign({}, state, {
        list: action.payload
      });
    case UPDATE_DARBUOTOJAI:
      return Object.assign({}, state, {
        items: action.items,
        list: action.list
      });
    case UPDATE_SORT:
      return Object.assign({}, state, {
        sort: action.payload
      });
    default:
      return state;
  }
}