import {
  PUT_IMOKOS_FILTRAS,
  RECEIVED_IMOKOS,
  REFRESH_IMOKOS,
  REQUEST_IMOKOS,
  SET_IMOKOS_FILTRAS
} from "../constants";

export default function imokos(
  state = {
    filtras: {},
    isFetching: false,
    shouldRefresh: false,
    items: null,
    list: null
  },
  action
) {
  switch (action.type) {
    case PUT_IMOKOS_FILTRAS:
      return Object.assign({}, state, { filtras: action.payload });
    case REFRESH_IMOKOS:
      return Object.assign({}, state, { shouldRefresh: true });
    case REQUEST_IMOKOS:
      return Object.assign({}, state, {
        isFetching: true,
        shouldRefresh: false
      });
    case RECEIVED_IMOKOS:
      return Object.assign({}, state, {
        isFetching: false,
        list: action.list,
        items: action.items,
        shouldRefresh: false
      });
    case SET_IMOKOS_FILTRAS:
      return Object.assign({}, state, { list: action.payload });
    default:
      return state;
  }
}
