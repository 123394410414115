import {
    PUT_TRANSPORTAS_FILTRAS,
    RECEIVED_TRANSPORTAS,
    REFRESH_TRANSPORTAS,
    REQUEST_TRANSPORTAS,
    SET_TRANSPORTAS_FILTRAS
  } from "../constants";
  
  export default function transportas(
    state = {
      filtras: {},
      isFetching: false,
      shouldRefresh: false,
      items: null,
      list: null
    },
    action
  ) {
    switch (action.type) {
      case PUT_TRANSPORTAS_FILTRAS:
        return Object.assign({}, state, { filtras: action.payload });
      case REFRESH_TRANSPORTAS:
        return Object.assign({}, state, { shouldRefresh: true });
      case REQUEST_TRANSPORTAS:
        return Object.assign({}, state, {
          isFetching: true,
          shouldRefresh: false
        });
      case RECEIVED_TRANSPORTAS:
        return Object.assign({}, state, {
          isFetching: false,
          list: action.list,
          items: action.items,
          shouldRefresh: false
        });
      case SET_TRANSPORTAS_FILTRAS:
        return Object.assign({}, state, { list: action.payload });
      default:
        return state;
    }
  }
  